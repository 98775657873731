import React from 'react'
import { Responsive } from 'responsive-react';
import { Row } from 'react-bootstrap'
import InvoiceCard from './InvoiceCard'
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'

type SmallScreenInvoicesListPropsTypes = {
    invoices: IInvoiceDocument[],
}

const SmallScreenInvoicesList = ({ invoices }: SmallScreenInvoicesListPropsTypes) => {

    return (
        <Responsive displayIn={["Mobile", "Tablet"]}>
            <Row>
                {invoices.map(invoice => {
                    return <InvoiceCard invoice={invoice}/>
                })}
            </Row>
        </Responsive>
    )
}

export default SmallScreenInvoicesList