import React from 'react'
import { Navbar } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { SETTINGS } from 'Constants/Routes'

const SNavbar = ({ userStore, authStore, uiStore }) => {

  return (
    <Navbar bg="light" expand="sm" sticky="top" variant="dark">
      <div onClick={() => uiStore.toggleSidebar()} 
        className="h-100 btn d-flex align-items-center sidebar-toggler">
        <i className="fas fa-bars"></i>
      </div>
      <ul>
        {/* <li><a href="#"><i className="fas fa-search"></i></a></li> */}
        <li>
          <div className="nav-icon-link"><i className="fas fa-bell"></i></div>
        </li>
        <li>
          <Link to={SETTINGS}>
            <div className="nav-icon-link"><i className="fas fa-cog"></i></div>
          </Link>
        </li>
        <li>
          <div className="nav-icon-link" onClick={() => authStore.logout()}>
            <i className="BTN fas fa-sign-out-alt"></i>
          </div>
        </li>
      </ul>
    </Navbar>
  )
}

export default inject('userStore', 'authStore', 'uiStore')(observer(SNavbar))