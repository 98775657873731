import React from 'react'
import { Row, Col, } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

const CounterContainer = ({ children }) => {
    return (
        <div className="d-flex flex-column text-center h-100 justify-content-center py-3 py-sm-3">
            {children}
        </div>
    )
}

const Counter = ({ title, totalCount, monthCount = undefined }) => {
    const { t } = useTranslation()

    return (
        <>
            <span>{t(title)}</span>
            <Row>
                {monthCount !== undefined &&
                    <Col>
                        <span className="text-muted">{t('month')}</span><br />
                        <span>{monthCount}</span>
                    </Col>
                }
                <Col>
                    <span className="text-muted">{t('total')}</span><br />
                    <span>{totalCount}</span>
                </Col>
            </Row>
        </>
    )
}

const Counters = ({ data }) => {
    return (
        <div className="h-100 d-flex flex-wrap p-2 border shadow">
            <Col xs={12} sm={12} md={4} lg={12} >
                <CounterContainer>
                    <Counter
                        title='providers'
                        totalCount={data.ProvidersCount}
                    />
                </CounterContainer>
            </Col>
            <Col xs={12} sm={12} md={4} lg={12} className="border-top" >
                <CounterContainer>
                    <Counter
                        title='withholdings'
                        monthCount={data.WithholdingsCountMonth}
                        totalCount={data.WithholdingsCountTotal}
                    />
                </CounterContainer>
            </Col>
            <Col xs={12} sm={12} md={4} lg={12} className="border-top" >
                <CounterContainer>
                    <Counter
                        title='invoices'
                        monthCount={data.InvoiceCountMonth}
                        totalCount={data.InvoiceCountTotal}
                    />
                </CounterContainer>
            </Col>
        </div>
    )
}

export default (observer(Counters))