import { decorate, observable, action, reaction } from 'mobx';
class configurationStore {
  remember: string | null = window.localStorage.getItem('rmb');
  documentsType: any[] | undefined = undefined;
  operationsType: any[] | undefined = undefined;
  taxes: any[] | undefined = undefined

  constructor() {
    this.loadFromLocalStorage()
    reaction(
      () => this.documentsType,
      documentsType => {
        if (documentsType) {
          let data = JSON.stringify(documentsType);
          localStorage.setItem('documentsType', data);
        } else {
          localStorage.removeItem('documentsType');
        }
      }
    );
    reaction(
      () => this.operationsType,
      operationsType => {
        if (operationsType) {
          let data = JSON.stringify(operationsType);
          localStorage.setItem('operationsType', data);
        } else {
          localStorage.removeItem('operationsType');
        }
      }
    );
    reaction(
      () => this.taxes,
      taxes => {
        if (taxes) {
          let data = JSON.stringify(taxes);
          localStorage.setItem('taxes', data);
        } else {
          localStorage.removeItem('taxes');
        }
      }
    );
  }

  loadFromLocalStorage() {
    const documentsType = localStorage.getItem('documentsType')
    const operationsType = localStorage.getItem('operationsType')
    const taxes = localStorage.getItem('taxes')
    if (!documentsType || !operationsType || !taxes) {
      // Sino no hay datos en el local storage significa que el ususario no ha seleccionado aún una
      // Compañia
      return
    }
    this.documentsType = JSON.parse(documentsType)
    this.operationsType = JSON.parse(operationsType)
    this.taxes = JSON.parse(taxes)
  }

  setRemember(email: string) {
    this.remember = email
    window.localStorage.setItem('rmb', email);
  }

  setDocumentsType(dct: any[]) {
    this.documentsType = dct
  }

  setOperationsType(opt: any[]) {
    this.operationsType = opt
  }

  setTaxes(taxes: any[]) {
    this.taxes = taxes
  }

  forgetAll() {
    this.documentsType = undefined;
    this.operationsType = undefined;
    this.taxes = undefined
  }
}

decorate(configurationStore, {
  remember: observable,
  taxes: observable,
  documentsType: observable,
  operationsType: observable,
  setRemember: action,
})

export default new configurationStore();
