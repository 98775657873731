import React from 'react'
import { inject, observer } from 'mobx-react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingSpinnerAlt from 'Components/LoadingSpinnerAlt'

const GenerateButton = ({ txtStore: store}) => {
    const { t } = useTranslation()

    function handleGenerateClick() {
        const resp = window.confirm(t('really_want_to_generate_txt?'))
        if(resp) store.createTxt()
    }

    return (
        <Row>
            <Col className="d-flex justify-content-center">
                {store.isGenerating && <LoadingSpinnerAlt />}
                {!store.isGenerating &&
                    <Button onClick={handleGenerateClick}>{t('generate')}</Button>
                }
            </Col>
        </Row>)
}

export default inject('txtStore')(observer(GenerateButton))