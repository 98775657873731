import React from 'react'
import {useAccordionToggle} from 'react-bootstrap'

const CustomAcordionToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      console.log(),
    );
  
    return (
      <span
        className="text-primary btn btn-link p-0"
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  }

  export default CustomAcordionToggle
