import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'

const RESOURCE = `/Reports`

const Reports = {
  //responseType: 'blob' necesario para la interpretación correcta del PDF
  withholding: idWithHolding =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/WithHoldingPdf/${idWithHolding}`, { responseType: 'blob' }).then(response => {
      if (response && response.status === 200) return response.data
    }),
  purchaseLedgerPdf: (From, To, TypeLedger, TypeFormat) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/PurchaseLedgerPdf/${From}/${To}/${TypeLedger}/${TypeFormat}`, { responseType: 'blob' }).then(response => {
      if (response && response.status === 200) return response.data
    }),
  createTxt: () =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/CreateTxt`, { responseType: 'blob' }).then(response => {
      if (response && response.status === 200) return response.data
    }),
  downloadTxt: txtNumber =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/DonwloadTxt/${txtNumber}`, { responseType: 'blob' }).then(response => {
      if (response && response.status === 200) return response.data
    }),
  sendEmailWithHoldingPdf: (IdWithHolding, mailsSend) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/SendEmailWithHoldingPdf`, { params: {IdWithHolding, mailsSend} }).then(response => {
      if (response && response.status === 200) return response.data
    }),
}

export default Reports 