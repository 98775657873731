import React from 'react'
import {Row, Col, Button} from 'react-bootstrap'
import './InvitationCard.css'

const InvitationCard = ({invitation}) => {
    return (
        <div className="w-100 bg-secondary s-invitation-card-container">
                <h2>Compañia: {invitation.company}</h2>
            <Row>
                <Col xs={0} sm={7} md={7}></Col>
                <Col xs={12} sm={5} md={5} className="d-flex justify-content-center">
                    <Button variant="danger" className="mr-2">Rechazar</Button>
                    <Button variant="primary" className="ml-2">Aceptar</Button>
                </Col>
            </Row>
        </div>
    )
}

export default InvitationCard