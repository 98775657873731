import { decorate, observable, action } from 'mobx';
import apiService from 'Service'
import defaultValues from 'Constants/DefaultValues'
import providersStore from './providersStore'
import Utilities from 'Utilities'
import Moment from 'moment'
import FileDownload from 'js-file-download'
import companyStore from "stores/companyStore"
import IInvoiceDocumentComplete from 'interfaces/service/Invoice/IInvoiceDocumentComplete'
import IInvoiceForDocumentSelection from 'interfaces/app/IInvoiceForDocumentSelection';
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument';
import invoicesStore from './invoicesStore';

class withholdingsStore {
  // Registro
  isLoading = true
  withholdings = []

  // Lista
  withholding = defaultValues.withholding

  // Selecció de documentos
  pendingDocuments: IInvoiceForDocumentSelection[] = []

  // Envio de correo
  showEmailSendModal = false
  emailTo = ''

  fetch(dateFrom = Moment().startOf('month').format('YYYY-MM-DD'),
    dateTo = Moment().endOf('month').format('YYYY-MM-DD'),
    initial = 0,
    take = 10,
  ) {
    this.isLoading = true
    this.withholdings = []
    apiService.Withholding.getByDate(dateFrom, dateTo, initial, take).then(data => {
      this.withholdings = data
      this.isLoading = false
    }).finally(() => this.isLoading = false)
  }

  updateWithholdingState(newData: any) {
    this.withholding = { ...this.withholding, ...newData }
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  resetProcess() {
    this.setIsLoading(true)
    this.withholding = defaultValues.withholding
    this.setIsLoading(false)
  }

  postNew(selectedDocuments: IInvoiceDocumentComplete[]) {
    if (providersStore.provider.Id === '') throw new Error('not_valid_provider_id')
    this.setIsLoading(true)
    const invoiceIds = selectedDocuments.map(doc => doc.Invoice.Id)
    const data = {
      WithHolding: { ...this.withholding, IdProvider: providersStore.provider.Id, Date: Moment(this.withholding.Date).format('yyyy-MM-DD') },
      IdInvoices: invoiceIds
    }
    return apiService.Withholding.add(data).then(data => {
      if (data) {
        this.withholding = data
        companyStore.increaseCompanyCurrentCorrelativeByOne()
        return true
      }
      return null
    })
      .finally(() => this.setIsLoading(false))
  }

  initProcess() {
    this.setIsLoading(true)
    this.withholding = defaultValues.withholding
    this.pendingDocuments = []
    this.setIsLoading(false)
  }

  setPendingDocuments(documets: IInvoiceForDocumentSelection[]) {
    this.pendingDocuments = documets
  }

  fetchPendingDocuments() {
    this.setIsLoading(true)
    apiService.DocumentCompletes.getPending(providersStore.provider.Id).then(Documents => {
      if (Documents) {
        const documentForSelection: IInvoiceForDocumentSelection[] = Documents.map(document => {
          let total = 0
          document.InvoiceDetails.forEach((invoiceDetail) => {
            total += invoiceDetail.TotalAmmount
          })

          return {
            ...document,
            Selected: true,
            CalculatedTotalAmount: Utilities.FormatCurrency(total)
          }
        })
        this.setPendingDocuments(documentForSelection)
      }
    }).finally(() => this.setIsLoading(false))
  }

  downloadDocument(idWithHolding: any, fileName = 'withholding') {
    return apiService.Reports.withholding(idWithHolding).then(data => {
      if (data) {
        FileDownload(data, fileName + '.pdf')
        return true
      };
    })
  }

  setShowEmailSendModal(value: boolean) {
    this.showEmailSendModal = value
  }

  setEmailTo(value: string) {
    this.emailTo = value
  }

  clear() {
    this.isLoading = true
    this.withholdings = []
    this.pendingDocuments = []
    this.withholding = defaultValues.withholding
    this.showEmailSendModal = false
    this.emailTo = ''
  }

  async deleteInvoice(invoice: IInvoiceDocument) {
    const success = await invoicesStore.deleteInvoice(invoice)
    if (success) {
      this.setPendingDocuments([...this.pendingDocuments.filter(x => x.Invoice.Id !== invoice.Id)])
    }
  }
}

decorate(withholdingsStore, {
  withholdings: observable,
  isLoading: observable,
  withholding: observable,
  pendingDocuments: observable,
  showEmailSendModal: observable,
  emailTo: observable,
  fetch: action,
  initProcess: action,
  updateWithholdingState: action,
  resetProcess: action,
  postNew: action,
  setPendingDocuments: action,
  fetchPendingDocuments: action,
  setShowEmailSendModal: action,
  setEmailTo: action,
  clear: action
})

export default new withholdingsStore();
