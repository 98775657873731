import React, {useState} from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import withholdingsStore from 'stores/withholdingsStore'
import emailSendModalStore from 'stores/Components/emailSendModalStore'
import LoadingSpinnerAlt from 'Components/LoadingSpinnerAlt'
import { isEmail } from 'react-multi-email';

// Tarjeta usada en la version de movil o tablet.
const ActionButtons = ({idWithholding, correlative, providerEmail}) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false)

    function download(){
        setIsDownloading(true)
        withholdingsStore.downloadDocument(idWithholding, correlative)
            .finally(()=>setIsDownloading(false))
    } 

    function showEmailModal(){
        emailSendModalStore.openModal(idWithholding, isEmail(providerEmail) ? [providerEmail] : [])
    }

    return (
        <>
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-view`}>
                        {t('preview')}
                    </Tooltip>
                }
            >
                <i className="fas fa-eye btn" />
            </OverlayTrigger>
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-send-by-email`}>
                        {t('send_by_email')}
                    </Tooltip>
                }
            >
                <i className="fas fa-envelope btn" onClick={showEmailModal} />
            </OverlayTrigger>

            {isDownloading && 
            <div className="d-inline-block action-button-loading-container" >
                <LoadingSpinnerAlt/>
            </div>}
            {!isDownloading && <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-download`}>
                        {t('download')}
                    </Tooltip>
                }
            >
                <i className="fas fa-download btn" onClick={download }/>
            </OverlayTrigger>}
        </>
    )
}

export default ActionButtons