import { decorate, observable, action } from 'mobx';
import apiService from 'Service'

class dashboardStore {
    isLoading = true
    data = undefined

    reset(){
        this.setIsLoading(true)
        this.setData(undefined)
    }

    setData(newData){
        this.data = newData
    }
    setIsLoading(value){
        this.isLoading = value
    }

    fetchData(){
        this.setIsLoading(true)
        this.setData({})
        apiService.Dashboards.get().then(data=>{
            this.setData(data)
        })
        .catch(err=>console.log(err))
        .finally(()=>{this.setIsLoading(false)})
    }
}

decorate(dashboardStore, {
    isLoading: observable,

    data: observable,
    setData: action,

    fetchData: action
})

export default new dashboardStore();
