/**
 * TODO:
 * Validar que no existas otra facturas con el mismo número
 * para el mismo proveedor
 */

import React from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller } from "react-hook-form";
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next'
import vlMessages from 'Constants/ValidationMessages'
import ErrorMessage from 'Components/ErrorMessage'
import * as yup from 'yup'
import DatePicker from 'Components/DatePicker'
import useStores from 'hooks/useStores'

type InvoiceInformationNewPropsType = {
    nextStep: () => void,
    previous?: () => void,
    cancel?: () => void
}

const InvoiceInformationNew = ({ nextStep, previous, cancel }: InvoiceInformationNewPropsType) => {
    const { t } = useTranslation();

    const { invoicesStore, configurationStore } = useStores()


    const validationSchema = yup.object().shape({
        EmitDate: yup.date().required(),
        Serie: yup.string(),
        InvoiceNumber: yup.string().required().numbers(),
        ControlNumber: yup.string(),
        IdDocumentType: yup.string().required().numbers(),
    });

    const { register, handleSubmit, errors, control } = useForm({ validationSchema: validationSchema });

    const onSubmit = (data: any) => {

        invoicesStore.updateInvoiceState(data)
        nextStep()
    };

    function handlePrevious() {
        if (previous) previous();
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h5>{t('invoice_data')}</h5>
            <Form.Group controlId="Date">
                <Form.Label>{t('date')}:</Form.Label>
                <Controller
                    as={DatePicker}
                    control={control}
                    name="EmitDate"
                    defaultValue={invoicesStore.invoice.EmitDate} />
                <ErrorMessage error={errors?.EmitDate} />
            </Form.Group>
            <Form.Group controlId="Serie">
                <Form.Label>{t('serie')}:</Form.Label>
                <Form.Control type="text"
                    name="Serie"
                    ref={register}
                    defaultValue={invoicesStore.invoice.Serie} />
                <ErrorMessage error={errors?.Serie} />
            </Form.Group>
            <Form.Group controlId="InvoiceNumber">
                <Form.Label>{t('invoice_number')}:</Form.Label>
                <Form.Control type="number"
                    min="0"
                    name="InvoiceNumber"
                    ref={register}
                    defaultValue={invoicesStore.invoice.InvoiceNumber} />
                <ErrorMessage error={errors?.InvoiceNumber} />
            </Form.Group>
            <Form.Group controlId="ControlNumer">
                <Form.Label>{t('control_number')}:</Form.Label>
                <Form.Control type="text"
                    name="ControlNumber"
                    ref={register}
                    defaultValue={invoicesStore.invoice.ControlNumber} />
                <ErrorMessage error={errors?.ControlNumber} />
            </Form.Group>
            {/* <Form.Group controlId="IdOperationType">
                <Form.Label>{t('operation_type')}:</Form.Label>
                <Form.Control as="select"
                    name="IdOperationType"
                    defaultValue={invoicesStore.invoice.IdOperationType}
                    ref={register({ required: vlMessages.REQUIRED })}>
                    {configurationStore.operationsType.map(opType => {
                        return <option
                            key={opType.Id}
                            value={opType.Id}>{opType.Description}</option>
                    })}
                </Form.Control>
                <ErrorMessage error={errors?.IdOperationType} />
            </Form.Group> */}
            <input
                type="hidden"
                name="IdOperationType"
                defaultValue={configurationStore.operationsType![0].Id} />
            <Form.Group controlId="IdDocumentType">
                <Form.Label>{t('document_type')}</Form.Label>
                <Form.Control as="select"
                    name="IdDocumentType"
                    defaultValue={invoicesStore.invoice.IdDocumentType}
                    ref={register({ required: vlMessages.REQUIRED })}>
                    {configurationStore.documentsType!.map(dcType => {
                        return <option value={dcType.Id}>{dcType.Description}</option>
                    })}
                </Form.Control>
                <ErrorMessage error={errors?.IdDocumentType} />
            </Form.Group>
            <Row>
                {previous &&
                    <Col xs={12} sm={12} md={6} lg={6} className="order-1 order-sm-1 order-md-0 mb-1 mb-sm-1">
                        <Button className="w-100"
                            variant="secondary"
                            onClick={() => handlePrevious()}>{t('back')}</Button>
                    </Col>
                }
                {cancel &&
                    <Col xs={12} sm={12} md={6} lg={6} className="order-1 order-sm-1 order-md-0 mb-1 mb-sm-1">
                        <Button className="w-100" variant="danger" onClick={cancel}>
                            {t('cancel')}
                        </Button>
                    </Col>
                }
                <Col xs={12} sm={12} md={(previous || cancel) ? 6 : 12} lg={(previous || cancel) ? 6 : 12} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                    <Button className="w-100" type="submit">{t('continue')}</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default observer(InvoiceInformationNew)