import React, { useState } from 'react'
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next'
import AmountsSection from './AmountsSection'
import FormSection from './FormSection'
import ButtonsSection from './ButtonsSection'
import { Button } from 'react-bootstrap'
import useStores from 'hooks/useStores'


const STEPS = {
    LIST: 1,
    ADD_AMOUNT: 2
}

type AmountsFormPropsType = {
    nextStep: () => void
    previous?: () => void
    cancel?: () => void
}

const AmountsForm = ({ nextStep, previous, cancel } : AmountsFormPropsType) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1)

    const { invoicesStore } = useStores()

    
    function handlePrevious() {
        if(previous) previous();
    }

    function handleSave() {
        invoicesStore.amounts.length
            ? nextStep()
            : alert(t('you_must_register_at_least_one_amount'))
    }

    function handleAddAmountButton() {
        setStep(STEPS.ADD_AMOUNT)
    }

    function handleBackToListButton() {
        setStep(STEPS.LIST)
    }

    return (
        <div>
            <h5>{t('invoice_details')}</h5>

            {step === STEPS.LIST && <>
                <AmountsSection />
                <Button onClick={handleAddAmountButton}>{t('add_amount')}</Button>
                <div className="pt-3"></div>

                <ButtonsSection
                    handleSave={handleSave}
                    handlePrevious={handlePrevious}
                    cancel={cancel} />
            </>
            }

            {step === STEPS.ADD_AMOUNT && <>
                <FormSection backToListHandler={handleBackToListButton} />
            </>}
        </div>
    )
}

export default observer(AmountsForm)