import React from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import Actions from './Actions'
import Utilities from 'Utilities'

const TxtsTable = inject("txtStore")(observer(({ txtStore: store }) => {
    const { t } = useTranslation()

    return (
        <Table striped bordered hover>
            <thead>
                <tr className="text-center">
                    <th>{t('date_generated')}</th>
                    <th>{t('number')}</th>
                    <th>{t('actions')}</th>
                </tr>
            </thead>
            <tbody>
                {store.txts.map(txt =>{
                    return <tr className="text-center">
                        <td>{Utilities.TransformDateTime(txt.DateGenerate)}</td>
                        <td>{txt.GenerateNumber}</td>
                        <td><Actions txt={txt}/></td>
                    </tr>
                })}
            </tbody>
        </Table>)
}))

export default TxtsTable