import { decorate, observable, action } from 'mobx';
import apiService from 'Service'
import defaultValues from 'Constants/DefaultValues'
import IProvider from 'interfaces/service/IProvider'

class providersStore {
  isLoading = true
  providers = []

  fetch(initial = 0, take = 100) {
    this.isLoading = true
    this.providers = []
    apiService.Providers.getAll(initial, take).then(data => {
      this.providers = data
    }).finally(() => this.isLoading = false)
  }

  setIsLoading(value: boolean) {
    this.isLoading = value
  }

  provider: IProvider = defaultValues.provider
  providerExists = false
  isSending = false


  setProvider(newProviderData: IProvider) {
    this.provider = { ...this.provider, ...newProviderData }
  }

  setIsSending(value: boolean) {
    this.isSending = value
  }

  setProviderExists(value: boolean) {
    this.providerExists = value
  }

  initProcess() {
    this.setIsLoading(true)
    this.provider = defaultValues.provider
    this.providerExists = false
    this.setIsLoading(false)
  }

  resetProcess() {
    this.setIsLoading(true)
    this.provider = { ...defaultValues.provider, Rif: this.provider.Rif }
    this.providerExists = false
    this.setIsLoading(false)
  }

  identificationLookup(rif: string) {
    this.setIsLoading(true)
    return apiService.Providers.getByRif(rif).then(provider => {
      if (!provider) {
        //Colocar el rif para que sea visible en el proximo formulario
        this.provider = { ...provider, Rif: rif }
        this.setProviderExists(false)
        return true
      }
      this.setProvider(provider)
      this.setProviderExists(true)
      return true

    }).catch(err => {
      throw err
    }).finally(() => this.setIsLoading(false))
  }

  idLookUp(id: string) {
    this.setIsLoading(true)
    this.provider = defaultValues.provider
    return apiService.Providers.getById(id).then(provider => {
      this.setProvider(provider)
      this.setProviderExists(true)
      return true
    }).catch(err => {
      if (err && err.response && err.response.status === 404) return false
    }).finally(() => this.setIsLoading(false))
  }

  addProvider(newProviderData: IProvider) {
    this.setIsLoading(true)
    newProviderData.WithholdingPercentageISLR = "0";
    this.setProvider(newProviderData)
    return apiService.Providers.add(this.provider).then(data => {
      this.setProviderExists(true)
      this.setProvider(data)
      return true
    }).finally(() => this.setIsLoading(false))
  }

  updateProvider(newProviderData: IProvider) {
    this.setIsLoading(true)
    this.setProvider(newProviderData)
    return apiService.Providers.update(this.provider).then(data => {
      if (data) {
        this.setProvider(data)
        this.setIsLoading(false)
        return true
      }
      return false
    }
    ).finally(() => this.setIsLoading(false))
  }

  clear() {
    this.isLoading = true
    this.providers = []
    this.provider = defaultValues.provider
    this.providerExists = false
    this.isSending = false
  }
}

decorate(providersStore, {
  providers: observable,
  isLoading: observable,
  provider: observable,
  providerExists: observable,
  fetch: action,
  initProcess: action,
  resetProcess: action,
  identificationLookup: action,
  idLookUp: action,
  addProvider: action,
  updateProvider: action,
  clear: action,
})

export default new providersStore();
