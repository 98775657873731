import React, { useState } from 'react'
import { observer } from 'mobx-react';
import { Redirect } from "react-router-dom";
import { Form, Button, Row, Col } from 'react-bootstrap';
import './Login.css'
import { COMPANY_SELECTION } from "../Constants/Routes";
import { useForm } from "react-hook-form";
import vlMessages from '../Constants/ValidationMessages'
import ErrorMessage from '../Components/ErrorMessage';
import LoadingSpinner from 'Components/LoadingSpinner'
import { LOGO_URL } from 'Constants/Media'
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle'
import useStores from 'hooks/useStores';

type LoginFormType = {
    Email: string,
    Password: string,
    PasswordRepeat?: string
}

const LoginPage = () => {
    const { t } = useTranslation();
    useTitle('log_in')

    const { register, handleSubmit, errors, getValues, watch } = useForm<LoginFormType>();
    const { userStore, authStore } = useStores()

    const [step, setStep] = useState(1)
    const [userExists, setUserExists] = useState(false)

    function renderStep1() {
        return (
            <>
                <div className="d-flex align-content-center mb-2" style={{ color: '#2c2d48;' }}>
                    {t('login_instructions')}
                </div>
                <Form.Group controlId="Email">
                    <Form.Control name="Email"
                        type="email"
                        placeholder={t('email')}
                        defaultValue={getValues('Email')}
                        ref={register({ required: vlMessages.REQUIRED })} />
                    <ErrorMessage error={errors?.Email} />
                </Form.Group>
                {authStore.inProgress
                    ? <div className="mb-3"><LoadingSpinner /></div>
                    : <>
                    <Button className="w-100 mb-2" type="submit">{t('log_in')}</Button>
                    <Button className="w-100 bg-white text-primary" type="submit">{t('sign_in')}</Button></>}
            </>
        )
    }

    function renderStep2() {
        return (
            <div>
                <Form.Group controlId="EmailDis">
                    <Form.Control name="Email"
                        type="email"
                        placeholder={t('email')}
                        defaultValue={getValues('Email')}
                        disabled
                        ref={register({ required: vlMessages.REQUIRED })} />
                    <ErrorMessage error={errors?.Email} />
                </Form.Group>
                <Form.Group controlId="Password">
                    <Form.Control name="Password"
                        type="password"
                        placeholder={t('password')}
                        ref={register({ required: vlMessages.REQUIRED })} />
                    <ErrorMessage error={errors?.Password} />
                </Form.Group>
                {!userExists &&
                    <Form.Group controlId="PasswordRepeat">
                        <Form.Control name="PasswordRepeat"
                            type="password"
                            placeholder={t('password_repeat')}
                            ref={register({
                                required: vlMessages.REQUIRED,
                                validate: value => value === watch('Password') || vlMessages.PASSWORD_DO_NOT_MATCH
                            })}
                        />
                        <ErrorMessage error={errors?.PasswordRepeat} />
                    </Form.Group>
                }
                {/* Botones */}
                {authStore.inProgress
                    ? <div className="mb-3"><LoadingSpinner /></div>
                    : <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="order-1 order-sm-1 order-md-0 mb-1 mb-sm-1">
                            <Button className="w-100"
                                variant="secondary"
                                onClick={() => { setStep(1); authStore.errors = {
                                    invalidLogin: false,
                                    passwordIncomplete: false
                                } }}>
                                {t('back')}
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                            <Button className="w-100"
                                type="submit">
                                {userExists ? t('log_in') : t('sign_in')}
                            </Button>
                        </Col>
                    </Row>
                }
            </div>
        )
    }

    function login(data: LoginFormType) {
        authStore.login(data.Email, data.Password).catch(err => { })
    }

    function sendRegister(data: LoginFormType) {
        authStore.register(data.Email, data.Password, data.PasswordRepeat)
    }

    function onSubmit(data: LoginFormType) {
        if (step === 1) {
            authStore.check(data.Email).then(ex => {
                setUserExists(ex)
                setStep(2)
            })
        }
        if (step === 2) {
            userExists ? login(data) : sendRegister(data)
        }
    }

    return (
        <div className="container-fluid h-100 ">
            <main role="main">
                <img className="wave" src="img/wave.png" alt="wave-background" />
                <div className="containerlogin">
                    <div className="img">
                        <h2 className="title">Sam Suite</h2>
                        <h2 className="title">Bienvenidos</h2>
                        <img src="img/bg.svg" alt="background" />
                    </div>
                    <div className="login-content formlogin">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="login-content">
                                <fieldset disabled={authStore.inProgress}>
                                    <img src={LOGO_URL} alt="logo"></img>
                                    <h2 className="title">Libro de Compras</h2>
                                    {step === 1 ? renderStep1() : renderStep2()}
                                    {
                                        authStore.errors?.invalidLogin &&
                                        <span>Intento fallido</span>}
                                    {authStore.errors?.passwordIncomplete &&
                                        <span>La contraseña debe poseer al menos un caracter especial o numerico, debe poseer al menos una mayuscula y una miniscula</span>
                                    }</fieldset>

                            </div>
                        </Form>
                    </div>
                </div>
            </main>
            {userStore.currentUser && userStore.currentUser.token && <Redirect to={{ pathname: COMPANY_SELECTION }} />}
        </div >
    )
};

export default observer(LoginPage)