import React, { useEffect } from 'react'
import useTitle from 'hooks/useTitle'
import txtStore from 'stores/txtStore'
import { Provider, inject, observer } from 'mobx-react'
import GenerateButton from './GenerateButton'
import Filter from './Filter'
import ListRender from './ListRender'
import Moment from 'moment'

const TxtList = inject("txtStore")(observer(({ txtStore: store }) => {
    const defaultDateFrom = Moment().startOf('month').format('YYYY-MM-DD')
    const defaultDateTo = Moment().endOf('month').format('YYYY-MM-DD')

    useTitle('txt_file')

    useEffect(() => {
        store.fetchTxts(defaultDateFrom, defaultDateTo)
        return () => {
            store.reset()
        }
    })

    return (<div className="mt-4">
        <div className="mt-2">
            <GenerateButton/>
        </div>
        <div className="mt-4">
            <Filter/>
        </div>
        <div className="mt-4">
            <ListRender/>
        </div>
    </div>)
}))

export default (() => {
    return <Provider txtStore={txtStore}><TxtList /></Provider>
})