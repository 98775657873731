import React from 'react'
import { Accordion, Card } from 'react-bootstrap'

const Body = ({children}) => 
    <Accordion.Collapse eventKey="0">
        <Card.Body>
            {children}
        </Card.Body>
    </Accordion.Collapse>

export default Body