import React, { useState, useEffect } from 'react'
import { AmountsForm, InvoiceInformation } from '../../../Components/Forms'
import {  observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useStores from 'hooks/useStores'
import IInvoiceDocumentComplete from 'interfaces/service/Invoice/IInvoiceDocumentComplete';

enum PURCHASE_REGISTRATION_STEPS {
    INVOICE_INFORMATION,
    AMOUNTS_INTRODUCTION
}

type PruchaseRegistrationPropsTypes = {
    onPurchaseRegistered: (doc: IInvoiceDocumentComplete) => void
    setIsAddingInvoice: React.Dispatch<React.SetStateAction<boolean>>
}

const PruchaseRegistration = ({ onPurchaseRegistered, setIsAddingInvoice }: PruchaseRegistrationPropsTypes) => {

    const [step, setStep] = useState(PURCHASE_REGISTRATION_STEPS.INVOICE_INFORMATION)
    const { t } = useTranslation();

    const { invoicesStore } = useStores()


    useEffect(() => {
        invoicesStore.initProcess()
        return () => {
            // Limpiar estados al salir
            invoicesStore.clear()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function previous() {
        switch (step) {
            case PURCHASE_REGISTRATION_STEPS.AMOUNTS_INTRODUCTION:
                setStep(PURCHASE_REGISTRATION_STEPS.INVOICE_INFORMATION)
                break;
            default:
                break;
        }
    }

    function nextStep() {
        switch (step) {
            case PURCHASE_REGISTRATION_STEPS.INVOICE_INFORMATION:
                setStep(PURCHASE_REGISTRATION_STEPS.AMOUNTS_INTRODUCTION)
                break;
            case PURCHASE_REGISTRATION_STEPS.AMOUNTS_INTRODUCTION:
                sendData()
                break;
            default:
                break;
        }
        if (step !== PURCHASE_REGISTRATION_STEPS.AMOUNTS_INTRODUCTION) {
            window.scrollTo(0, 1)
        }
    }

    function backToWitholdingSelection() {
        setIsAddingInvoice(false)
    }

    function sendData() {
        invoicesStore.postNewCompleteDocument().then(res => {
            if (res) {
                alert(t('invoice_registered_successfully'))
                onPurchaseRegistered(res)
                setIsAddingInvoice(false)
            }
        })
    }

    return (
        <div className="boder shadow px-3 pb-4 pt-2 rounded">
            <div className="border-bottom mb-2 d-flex">
                <h3>{t('adding_invoice')}</h3>
            </div>
            <div>
                {step === PURCHASE_REGISTRATION_STEPS.INVOICE_INFORMATION &&
                    <InvoiceInformation
                        nextStep={nextStep}
                        cancel={backToWitholdingSelection}
                    />}
                {step === PURCHASE_REGISTRATION_STEPS.AMOUNTS_INTRODUCTION &&
                    <AmountsForm
                        nextStep={nextStep}
                        previous={previous}
                        cancel={backToWitholdingSelection} />}
            </div>
        </div>
    )
}
export default observer(PruchaseRegistration)

