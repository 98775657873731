import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'Components/LoadingSpinner'
import { Row, Col, Button } from 'react-bootstrap'
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';


const EmailSendForm = ({ emailSendModalStore }) => {
    const { t } = useTranslation()

    function onSubmit(e) {
        e.preventDefault()
        emailSendModalStore.send()
    }

    return (<>
        <Form onSubmit={onSubmit} className="pb-4">
            <fieldset disabled={emailSendModalStore.isLoading}>
                <ReactMultiEmail
                    placeholder={t('insert_email_addresses')}
                    emails={emailSendModalStore.emails}
                    onChange={(_emails) => {
                        emailSendModalStore.setEmails(_emails);
                    }}
                    validateEmail={email => {
                        return isEmail(email); // return boolean
                    }}
                    getLabel={(
                        email,
                        index,
                        removeEmail = (index) => { }
                    ) => {
                        return (
                            <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
                <small>{t('press_space_enter_or_semicolon_to_add_email')}</small>
                <div className="mt-3">
                {emailSendModalStore.isLoading
                    ? <LoadingSpinner />
                    : <>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                                <Button className="w-100" type="submit" disabled={emailSendModalStore.emailsAreValid?false:true}>
                                    {t('send')}
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                </div>
            </fieldset>
        </Form>
    </>)
}

export default inject('emailSendModalStore')(observer(EmailSendForm))