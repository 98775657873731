import React, {Component} from 'react'
import { Redirect} from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { COMPANY_SELECTION } from "../Constants/Routes";

class AdminRoute extends Component{
    render(){

        const {companyStore} = this.props;
        return(
            <div>
            {companyStore.selectedCompany !== undefined && companyStore.selectedCompany.isThirdParty === false ? <div>{this.props.Component}</div>:<Redirect to={{ pathname: COMPANY_SELECTION}}/>  }
            </div>
        )
    }
}

export default inject("companyStore")(observer(AdminRoute))