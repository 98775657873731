import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import CustomAccordionToggle from './CustomAcordionToggle'
import { useTranslation } from 'react-i18next';

const Header = ({children}) => {
    const { t } = useTranslation();
    
    return (
        <Card.Header >
            <div className="px-2"> 
            <>
                {children}
            </>
            <Row className="border-top">
                <Col className="text-center">
                    <CustomAccordionToggle eventKey="0">
                        {t('details')}
                    </CustomAccordionToggle>
                </Col>
            </Row>
            </div>
        </Card.Header>)
};

export default Header