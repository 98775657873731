import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'Components/LoadingSpinner'
import useStores from 'hooks/useStores';

type ButtonsSectionPropsTypes = {
    handleSave: () => void
    handlePrevious: () => void
    cancel?: () => void
}

const ButtonsSection = ({ handleSave, handlePrevious, cancel }: ButtonsSectionPropsTypes) => {
    const { t } = useTranslation();

    const { invoicesStore } = useStores()

    if (invoicesStore.isLoading) return <LoadingSpinner />

    return (<>
        <Row>
            {cancel &&
                <Col xs={12} sm={12} md={4} className="order-2 order-sm-2 order-md-0 mb-1 mb-sm-1">
                    <Button className="w-100" variant="danger" onClick={cancel}>
                        {t('cancel')}
                    </Button>
                </Col>
            }
            <Col xs={12} sm={12} md={cancel ? 4 : 6} lg={cancel ? 4 : 6} className="order-1 order-sm-1 order-md-1 mb-1 mb-sm-1">
                <Button className="w-100"
                    variant="secondary"
                    onClick={handlePrevious}>{t('back')}</Button>
            </Col>
            <Col xs={12} sm={12} md={cancel ? 4 : 6} lg={cancel ? 4 : 6} className="order-0 order-sm-0 order-md-2 mb-1 mb-sm-1">
                <Button className="w-100" disabled={invoicesStore.amounts.length === 0}
                    onClick={handleSave}>{t('finish')}</Button>
            </Col>
        </Row>
    </>
    )
}

export default observer(ButtonsSection)