import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import vlMessages from 'Constants/ValidationMessages'
import ErrorMessage from 'Components/ErrorMessage'
import { useTranslation } from 'react-i18next'
import LoadingSpinnerAlt from 'Components/LoadingSpinnerAlt'
import { Row, Col, Button } from 'react-bootstrap'
import apiService from 'Service'
import FileDownload from 'js-file-download'
import Moment from 'moment'
import PURCHASE_LEDGER_FORMATS, { appendLedgerFormatExtensionToText } from 'Constants/PurchaseLedgerFormats'
import PURCHASE_LEDGER_TYPES from 'Constants/PurchaseLedgerTypes'

const PurchaseLedger = () => {
    const { register, handleSubmit, errors } = useForm();
    const { t } = useTranslation()

    const [isDownloading, setIsDownloading] = useState(false)

    function onSubmit(data) {
        setIsDownloading(true)
        apiService.Reports.purchaseLedgerPdf(data.From, data.To, data.TypeLedger, data.TypeFormat)
            .then(fileData => {
                if (fileData) {
                    const filename = appendLedgerFormatExtensionToText(parseInt(data.TypeFormat), "detallado")
                    console.log(filename)
                    FileDownload(fileData, filename)
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.status === 404) {
                    alert(t('you_dont_have_invoices_on_that_date_range'))
                }
            })
            .finally(() => setIsDownloading(false))
    }


    return (<div className="mt-4">
        <h2>{t('purchase_ledger')}</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={isDownloading}>
                <Row>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="lgrepFrom">
                            <Form.Label>{t('date_from')}</Form.Label>
                            <Form.Control type="date"
                                autoFocus
                                name="From" ref={register({ required: vlMessages.REQUIRED })}
                                defaultValue={Moment().startOf('month').format('YYYY-MM-DD')}
                            />
                            <ErrorMessage error={errors?.From} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="lgrepTo">
                            <Form.Label>{t('date_to')}</Form.Label>
                            <Form.Control type="date"
                                autoFocus
                                name="To" ref={register({ required: vlMessages.REQUIRED })}
                                defaultValue={Moment().endOf('month').format('YYYY-MM-DD')}
                            />
                            <ErrorMessage error={errors?.To} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                        <Form.Group controlId="lgrepTypeLedger">
                            <Form.Label>{t('report_type')}:</Form.Label>
                            <Form.Control as="select"
                                name="TypeLedger"
                                ref={register({ required: vlMessages.REQUIRED })}
                            >
                                <option value={PURCHASE_LEDGER_TYPES.DETAILED.value}>
                                    {t(PURCHASE_LEDGER_TYPES.DETAILED.description)}
                                </option>
                                {/* <option value={PURCHASE_LEDGER_TYPES.RESUME.value}>
                                    {t(PURCHASE_LEDGER_TYPES.RESUME.description)}
                                </option> */}
                            </Form.Control>
                            <ErrorMessage error={errors?.TypeLedger} />
                        </Form.Group>

                    </Col>
                    <Col xs={12} md={2}>

                        <Form.Group controlId="reportFormat">
                            <Form.Label>{t('report_format')}:</Form.Label>
                            <Form.Control as="select"
                                name="TypeFormat"
                                ref={register({ required: vlMessages.REQUIRED, valueAsNumeric: true })}
                            >
                                {Object.values(PURCHASE_LEDGER_FORMATS).map(format => {
                                    return <option key={format.value} value={format.value}>{format.description}</option>
                                })}
                            </Form.Control>
                            <ErrorMessage error={errors?.TypeFormat} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        {isDownloading && <LoadingSpinnerAlt />}
                        {!isDownloading &&
                            <Button type="submit">
                                {t('download')}
                            </Button>
                        }

                    </Col>
                </Row>
            </fieldset>
        </Form>
    </div>)
}

export default PurchaseLedger