import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import WithholdingEmailSendModal from 'Components/Modals/WithholdingEmailSendModal'
import { Row, Col, Button } from 'react-bootstrap'
import LoadingSpinnerAlt from 'Components/LoadingSpinnerAlt'
import { isEmail } from 'react-multi-email';
import useStores from 'hooks/useStores';

const ButtonWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="border shadow" style={{ width: '200px', height: '200px' }} >
            {children}
        </div>
    )
}

const FinalOptions = () => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false)

    const { providersStore, withholdingsStore, emailSendModalStore } = useStores()

    function download() {
        if (!isDownloading) {
            setIsDownloading(true)
            withholdingsStore.downloadDocument(
                withholdingsStore.withholding.Id, withholdingsStore.withholding.Correlative
            )
                .finally(() => setIsDownloading(false))
        }
    }

    function showEmailModal() {
        emailSendModalStore.openModal(withholdingsStore.withholding.Id, isEmail(providersStore.provider.Email) ? [providersStore.provider.Email] : [])
    }

    function handleGenerateAnother() {
        window.location.reload()
    }

    return (<>
        <div className="mt-2 ">
            <div className="text-center"><h2>{t('withholdng_succesfull_generate')}</h2></div>
            <Row className="mt-2">
                <Col xs={12} sm={6} className="d-flex justify-content-center mt-5 mt-sm-0">
                    <ButtonWrapper>
                        <div
                            className="btn w-100 h-100 d-flex justify-content-center align-items-center"
                            onClick={() => showEmailModal()}>
                            <div>
                                <i className="fas fa-envelope mb-1" style={{ fontSize: '48px' }} />
                                <p style={{ fontSize: '20px' }}>{t('send_by_email')}</p>
                            </div>
                        </div>
                    </ButtonWrapper>
                </Col>
                <Col xs={12} sm={6} className="d-flex justify-content-center mt-5 mt-sm-0">
                    <ButtonWrapper>
                        {isDownloading &&
                            <div className="h-100 w-100 p-2 d-flex justify-content-center align-items-center">
                                <LoadingSpinnerAlt dimension={50} percentage={true} />
                            </div>}
                        {!isDownloading &&
                            <div
                                className="btn w-100 h-100 d-flex justify-content-center align-items-center"
                                onClick={() => download()}>
                                <div>
                                    <i className="fas fa-download mb-1" style={{ fontSize: '48px' }} />
                                    <p style={{ fontSize: '20px' }}>{t('download')}</p>
                                </div>
                            </div>
                        }
                    </ButtonWrapper>
                </Col>
            </Row>
            <div className="mt-5 mb-3 text-right">
                <Button onClick={handleGenerateAnother}>
                    {t('generate_another_withholding')}
                </Button>
            </div>
            <WithholdingEmailSendModal />

        </div>
    </>
    )
}

export default observer(FinalOptions)