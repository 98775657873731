// Devulve la fecha actual en un formato valido 
// para los <input/> de tipo Date
const CurrentDate = (format = 'yyyy-mm-dd') => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //Enero es 0!
    const yyyy = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }

    let currentDate
    switch (format) {
        case 'yyyy-mm-dd':
            currentDate = yyyy + '-' + mm + '-' + dd;
            break;
        case 'dd-mm-yyyy':
            currentDate = dd + '-' + mm + '-' + yyyy;
            break;
        default:
            currentDate = yyyy + '-' + mm + '-' + dd;
            break;
    }

    return currentDate
};

export default CurrentDate