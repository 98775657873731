import React, { useState, useEffect, useRef } from 'react'
import { Button, Table, Row, Col } from 'react-bootstrap'
import PruchaseRegistration from '../PruchaseRegistration'
import Utilities from 'Utilities'
import { useTranslation } from 'react-i18next';
import DocumentCard from './DocumentCard'
import { Responsive } from 'responsive-react';
import LoadingSpinner from 'Components/LoadingSpinner'
import { observer } from 'mobx-react'
import useStores from 'hooks/useStores'
import ActionButtons from './ActionButtons'
import IInvoiceDocumentComplete from 'interfaces/service/Invoice/IInvoiceDocumentComplete';
import IInvoiceForDocumentSelection from 'interfaces/app/IInvoiceForDocumentSelection';

type DocumentSelectionFormPropsTypes = {
    parentPrevious: () => void,
    nextStep: () => void
}

const DocumentSelectionForm = ({ parentPrevious, nextStep }: DocumentSelectionFormPropsTypes) => {
    const { t } = useTranslation()

    const { withholdingsStore } = useStores()


    //Control de modal para agregar factura
    const [isAddingInvoice, setIsAddingInvoice] = useState(false)

    //Referencia para manejar el check de marcar/desmarcar todo
    const allStatusCheckbox = useRef<HTMLInputElement>(null)

    useEffect(() => {
        withholdingsStore.fetchPendingDocuments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Se desmarca el check de todos las selecciones si una de ellas
    // no está marcada
    useEffect(() => {
        if (allStatusCheckbox.current && !withholdingsStore.isLoading) {
            let allChecked = true
            withholdingsStore.pendingDocuments.forEach(document => {
                if (!document.Selected) allChecked = false
            })
            allStatusCheckbox.current.checked = allChecked
        }
    }, [withholdingsStore.pendingDocuments, withholdingsStore.isLoading]);

    function handleAllCheckboxControlChange(event: React.ChangeEvent<HTMLInputElement>) {
        let newState = withholdingsStore.pendingDocuments
        newState.forEach(document => document.Selected = event.target.checked)
        withholdingsStore.setPendingDocuments([...newState])
    }

    function handleSelectionChange(document: IInvoiceForDocumentSelection) {
        let newState = withholdingsStore.pendingDocuments;
        newState.forEach((pendingDocument) => {
            if (pendingDocument.Invoice.Id === document.Invoice.Id) {
                pendingDocument.Selected = !pendingDocument.Selected
            }
        })
        withholdingsStore.setPendingDocuments([...newState])
    }

    function handlePrevious() {
        const result = window.confirm(t('actual_selection_will_be_lost'))
        if (result) parentPrevious()
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleModalClose() {
        const result = window.confirm(t('do_you_really_want_to_quit_the_process'))
        if (result) setIsAddingInvoice(false)

    }

    function onPurchaseRegistered(doc: IInvoiceDocumentComplete) {

        // Solo agregar el documento a la lista si tiene impuestos (determinado por el servicio)
        if (doc.IsNotWithholding) return;

        let total = 0
        doc.InvoiceDetails.forEach((invoiceDetail) => {
            total += invoiceDetail.TotalAmmount
        })



        const selectableDocument: IInvoiceForDocumentSelection = {
            ...doc,
            Selected: true,
            CalculatedTotalAmount: Utilities.FormatCurrency(total)
        }

        withholdingsStore.setPendingDocuments([
            ...withholdingsStore.pendingDocuments,
            selectableDocument
        ])
    }

    function generateWithholding() {
        let selectedDocuments = withholdingsStore.pendingDocuments.filter(doc => doc.Selected === true)
        if (selectedDocuments.length) {
            withholdingsStore.postNew(selectedDocuments).then(res => {
                if (res) {
                    nextStep()

                }
            })
        } else {
            alert(t('you_must_select_at_leats_one_document'))
        }
    }

    function renderButtons() {
        return <>
            {withholdingsStore.isLoading
                ? <LoadingSpinner />
                : <Row>
                    <Col xs={6} sm={6} md={4} lg={4} className="order-1 order-sm-1 order-md-0 mb-0 mb-sm-0">
                        <Button className="w-100"
                            variant="secondary"
                            onClick={handlePrevious} >{t('back')}
                        </Button>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} className="order-2 order-sm-2 order-md-1 mb-1 mb-sm-1">
                        <Button className="w-100"
                            variant="success"
                            onClick={() => { setIsAddingInvoice(true) }} >{t('add_invoice')}
                        </Button>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="order-0 order-sm-0 order-md-2 mb-2 mb-sm-2">
                        <Button className="w-100"
                            variant="primary"
                            onClick={generateWithholding} >{t('generate_withholding')}
                        </Button>
                    </Col>
                </Row>
            }
        </>
    }

    if (isAddingInvoice) {
        return <PruchaseRegistration
            onPurchaseRegistered={onPurchaseRegistered}
            setIsAddingInvoice={setIsAddingInvoice}></PruchaseRegistration>
    }

    if (withholdingsStore.isLoading) return <LoadingSpinner />

    return (
        <div>
            <h5>{t('documents_selection')}</h5>
            {renderButtons()}

            {withholdingsStore.pendingDocuments.length === 0 &&
                <div className="mt-2">
                    <h4 className="text-center">{t('not_pending_invoices')}</h4>
                </div>
            }

            {withholdingsStore.pendingDocuments.length > 0 &&
                <>
                    <span>{t('select_all')}</span><br></br>
                    <input type="checkbox" onChange={handleAllCheckboxControlChange} ref={allStatusCheckbox} />
                    <fieldset disabled={withholdingsStore.isLoading}>
                        <Responsive displayIn={["Laptop"]}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nro Factura</th>
                                        <th>Nro Control</th>
                                        <th>Serie</th>
                                        <th>Monto Total</th>
                                        <th>Fecha</th>
                                        <th>Seleccionado</th>
                                        <th>{t('actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {withholdingsStore.pendingDocuments.map((document) => {
                                        return <tr className="text-center" key={document.Invoice.Id}>
                                            <td>{document.Invoice.InvoiceNumber}</td>
                                            <td>{document.Invoice.ControlNumber}</td>
                                            <td>{document.Invoice.Serie}</td>
                                            <td className="text-right">{document.CalculatedTotalAmount}</td>
                                            <td>{Utilities.TransformDate(document.Invoice.EmitDate)}</td>
                                            {<td><input type="checkbox" checked={document.Selected} onChange={() => { handleSelectionChange(document) }} /></td>}
                                            <td><ActionButtons invoice={document.Invoice} /></td>
                                        </tr>
                                    })}

                                </tbody>
                            </Table>
                        </Responsive>
                        <Responsive displayIn={["Mobile", "Tablet"]}>
                            <Row>

                                {withholdingsStore.pendingDocuments.map((document) => {
                                    return <DocumentCard
                                        document={document}
                                        key={document.Invoice.Id}
                                        handleSelectionChange={handleSelectionChange} />
                                })}
                            </Row>

                        </Responsive>
                    </fieldset>
                </>
            }
        </div>
    )
}

export default observer(DocumentSelectionForm)