import React from 'react'
import './index.scss'
import './index.css'
import { LOGO_URL } from 'Constants/Media'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  APP,
  INVOICES_LIST,
  INVOICES_ADD,
  PROVIDERS_LIST,
  WITHHOLDINGS_GENERATION,
  WITHHOLDINGS_LIST,
  REPORTS_PURCHASELEDGER,
  TXT_SECTION,
  SETTINGS
}
  from 'Constants/Routes'
import SLink from './SLink'
// Hay que ajustar DropDown a como está en vsap
//import DropDown from './DropDown'
import {Responsive} from 'responsive-react'

const SSidebar = ({ uiStore, userStore, companyStore, authStore }) => {
  const { t } = useTranslation()

  function goToCompanySelection() {
    companyStore.fogetSelectedCompany()
  }

  function closeSidebar(){
    uiStore.closeSidebar()
  }

  return (
    <div className={"ssidebar overflow-auto text-light vh-100 " + (uiStore.isSidebarVisible ? "active" : "collapsed")}>
      <div className="sidebar-menu pb-4">
        <center className="profile">
        <Responsive displayIn={["Mobile","Tablet"]}>
          <div className="p-2 position-absolute inner-sidebar-toggler" style={{ top: "1rem", right: "1rem", cursor: "pointer" }}>
            <i onClick={closeSidebar} className="fas fa-bars"></i>
          </div>
        </Responsive>
          <section className="user-info w-100 text-light p-3">
            <SLink>
              <Link to={APP} className="d-flex justify-content-center h-100">
                <img src={LOGO_URL} height="42px" alt="logo"></img>
              </Link>
            </SLink>
            <i className="fas fa-user-tie"></i><span className="ml-1 small">{userStore.currentUser.EMail}</span><br />
            <i className="fas fa-building"></i><span className="ml-1 small">{companyStore.selectedCompany.BusinessName}</span><br />
          </section>
        </center>
        <li className="item">
          <SLink>
            <Link to={APP} className="menu-btn">
              <i className="fas fa-tachometer-alt" />
              <span>{t('dashboard')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={WITHHOLDINGS_GENERATION} className="menu-btn">
              <i className="fas fa-file-export" />
              <span>{t('generate_withholding')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={WITHHOLDINGS_LIST} className="menu-btn">
              <i className="fas fa-file-invoice" />
              <span>{t('list_of') + ' ' + t('withholdings')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={INVOICES_ADD} className="menu-btn">
              <i className="fas fa-file-signature" />
              <span>{t('add_invoice')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={INVOICES_LIST} className="menu-btn">
              <i className="fas fa-file-contract" />
              <span>{t('list_of') + ' ' + t('invoices')}</span>
            </Link>
          </SLink>
        </li>

        <li className="item">
          <SLink>
            <Link to={PROVIDERS_LIST} className="menu-btn">
              <i className="fas fa-user-tie" />
              <span>{t('list_of') + ' ' + t('providers')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={REPORTS_PURCHASELEDGER} className="menu-btn">
              <i className="fas fa-file-contract" />
              <span>{t('purchase_ledger')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={TXT_SECTION} className="menu-btn">
              <i className="fas fa-file-contract" />
              <span>{t('txt_file')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link onClick={goToCompanySelection} className="menu-btn">
              <i className="fas fa-building" />
              <span>{t('select') + ' ' + t('company')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link to={SETTINGS} className="menu-btn">
              <i className="fas fa-cog" />
              <span>{t('settings')}</span>
            </Link>
          </SLink>
        </li>
        <li className="item">
          <SLink>
            <Link onClick={authStore.logout} className="menu-btn">
              <i className="fas fa-sign-out-alt" />
              <span>{t('log_out')}</span>
            </Link>
          </SLink>
        </li>
      </div>
    </div>
  )
}

export default inject("uiStore", "userStore", "companyStore", "authStore")(observer(SSidebar))