import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { APP, LOGIN } from './Constants/Routes'
import Login from './Pages/Login'
import AppInside from './Components/AppInside'
import ReactNotification from 'react-notifications-component'

const App = () => {
  return (<Router>
    <div>
      <ReactNotification />
      <Switch>
        <Route exact path={LOGIN} component={Login} />
        <AppInside path={APP} />
        <Redirect to={LOGIN} />
      </Switch>
    </div>
  </Router >)
}


export default App;
