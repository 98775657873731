import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'

const RESOURCE = `/Dashboards`

const Dashboards = {
  get: () =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}`).then(response => {
        if(response && response.status===200) return response.data
    }),
}

export default Dashboards