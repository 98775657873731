import React, { useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useForm, Controller } from "react-hook-form";
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next'
import vlMessages from 'Constants/ValidationMessages'
import ErrorMessage from 'Components/ErrorMessage'
import * as yup from 'yup';
import CurrencyInput from 'react-currency-input-field';
import numeral from 'numeral'
import Utilities from 'Utilities'
/**
 * 16/08/2021: el import de numeral/locales/es es muy importante, no borrar.
 *              si es posible buscar una alternativa a Numeral Js
 */
import 'numeral/locales/es'
import useStores from 'hooks/useStores';
numeral.locale('es')


const FormSection = ({ backToListHandler }) => {

    const { t } = useTranslation();
    
    const {invoicesStore, configurationStore, uiStore} = useStores()

    const validationSchema = yup.object().shape({
        TaxBase: yup.string(),
    })

    const { register, handleSubmit, errors, reset, setValue, getValues, control } = useForm({ validationSchema: validationSchema });

    // Si el usuario retrocede, se mantienen los montos y al volver a este forumario
    // se debe determinar cual fue el id más alto asignado anteriormente
    const [idCounter, setIdCounter] = useState(
        invoicesStore.amounts.length ? Math.max.apply(Math, invoicesStore.amounts.map(function (amount) { return amount.Row + 1; })) : 1)

    // Controla que no se muestre el resto del formulario
    // hasta que se seleccione un impuesto valido
    const [isValidSelectedTax, setIsValidSelectedTax] = useState(false)

    // Para el calculo automatico
    const [totalTax, setTotalTax] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)

    function onAmountSubmit(data) {
        data = {
            TaxType: parseInt(data.TaxType),
            TaxPorcentage: parseFloat(data.TaxPorcentage),
            TaxBase: numeral(data.TaxBase).value(),
            IdInvoice: '',
            Row: idCounter,
            TaxAmmount: numeral(totalTax).value(),
            TotalAmmount: numeral(totalAmount).value(),
            TaxFree: parseInt(data.TaxPorcentage) === 0 ? numeral(totalAmount).value() : 0,
            Discount: 0,
        }

        invoicesStore.addAmount(data)
        uiStore.addNotification(uiStore.appNotifications.SUCCESS_ADDED)
        setIdCounter(idCounter + 1)
        reset()
        backToListHandler()
    }

    function handleTaxTypeChange(e) {
        setIsValidSelectedTax(true)
        setHiddenTaxPorcentageValue(e.target.value)
        calculateTaxandTotal(getValues('TaxBase'))
    }

    function setHiddenTaxPorcentageValue(taxTypeId) {
        const value = configurationStore.taxes.find(x => x.Id === parseInt(taxTypeId)).Value
        if (!value) {
            setValue('TaxPorcentage', 0)
        }
        setValue('TaxPorcentage', value)
    }

    // el valor va etre [] porque react-hooks-form control este input
    function handleTaxBaseChange([value]) {
        calculateTaxandTotal(value)
        return value
    }

    function calculateTaxandTotal(base) {
        const tempTotalTax = Utilities.FormatCurrency(numeral(base).value() * (getValues('TaxPorcentage') / 100))
        const tempTotalAmount = Utilities.FormatCurrency(numeral(base).value() + numeral(tempTotalTax).value())
        setTotalTax(only2Decimals(tempTotalTax))
        setTotalAmount(only2Decimals(tempTotalAmount))
    }
    

    function handleTotalTaxChange(e) {
        setTotalTax(e)
        calculateTotalFromTaxChange(e)
    }

    function calculateTotalFromTaxChange(tax) {
        const tempTotalAmount = Utilities.FormatCurrency(numeral(getValues('TaxBase')).value() + numeral(tax).value())
        setTotalAmount(only2Decimals(tempTotalAmount))
    }

    function handleTotalAmountChange(e) {
        setTotalAmount(e)
    }

    function only2Decimals(value){
        return numeral(value).format('0.00')
    }

    return (
        <Form onSubmit={handleSubmit(onAmountSubmit)} className="">
            <b className="text-center"><span>{t('amount_information')}</span></b>
            <Form.Group controlId="TaxTypegr">
                <Form.Label>{t('vat_type')}:</Form.Label>
                
                <Form.Control as="select"
                    name="TaxType"
                    onChange={handleTaxTypeChange}
                    ref={register({ required: vlMessages.REQUIRED })}
                >
                    <option disabled selected>{t('select_for_continue')}</option>
                    {configurationStore.taxes.map(tax => {
                        return <option value={tax.Id}>{tax.Description + " - " + tax.Value + "%"}</option>
                    })}
                </Form.Control>
                <ErrorMessage error={errors?.TaxType} />
            </Form.Group>
            <Form.Control type="hidden" name="TaxPorcentage" ref={register} />
            {isValidSelectedTax && <>
                <Form.Group controlId="blBase">
                    <Form.Label>{t('tax_base')}</Form.Label>
                    <Controller
                        as={CurrencyInput}
                        allowDecimals={true}
                        decimalsLimit={2}
                        className="form-control"
                        decimalSeparator=","
                        groupSeparator="."
                        onChange={handleTaxBaseChange}
                        name="TaxBase"
                        control={control}
                    />
                    <ErrorMessage error={errors?.TaxBase} />
                </Form.Group>

                <Form.Group controlId="total_tax">
                    <Form.Label>{t('vat')}
                    </Form.Label>
                    <CurrencyInput
                        id="taxTotal"
                        allowDecimals={true}
                        decimalsLimit={2}
                        className="form-control"
                        value={totalTax}
                        onChange={handleTotalTaxChange}
                        decimalSeparator=","
                        groupSeparator="."
                />
                </Form.Group>
                <Form.Group controlId="total">
                    <Form.Label>{t('total')}</Form.Label>
                    <CurrencyInput
                        id="totalAmount"
                        allowDecimals={true}
                        decimalsLimit={2}
                        className="form-control"
                        value={totalAmount}
                        onChange={handleTotalAmountChange}
                        decimalSeparator=","
                        groupSeparator="."
                />
                </Form.Group>
            </>}

            <Row>
                <Col>
                    <Button
                        className="w-100"
                        variant="secondary"
                        onClick={backToListHandler}>
                        {t('back')}
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={!isValidSelectedTax}
                        className="w-100"
                        type="submit">
                        {t('add')}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default observer(FormSection)