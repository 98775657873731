import { decorate, observable, action } from 'mobx';
import apiService from 'Service'
import Moment from 'moment'

class WithholdingListStore {
    isLoading = true
    withholdings = []
    totalPages = undefined
    currentPage = undefined
    perPage = 10
    skip = 0

    fetch(
        dateFrom = Moment().startOf('month').format('YYYY-MM-DD'),
        dateTo = Moment().endOf('month').format('YYYY-MM-DD'),
        initial = 0,
        take = 100) {
        this.isLoading = true
        this.withholdings = []
        apiService.Withholding.getByDate(dateFrom, dateTo, initial, take).then(data => {
            if (data) this.processData(data)
        }).finally(() => this.isLoading = false)
    }

    processData(data) {
        this.isLoading = true
        /* const registerCount = data.count
        this.totalPages = (Math.ceil(registerCount / this.perPage)) */
        this.withholdings = data
        this.isLoading = false
    }

    moveToPage(pageNumber) {
        this.currentPage = pageNumber
        this.skip = Math.ceil(pageNumber * this.perPage)
        this.fetch()
    };

    clear() {
        this.isLoading = true
        this.withholdings = []
        this.totalPages = undefined
        this.currentPage = undefined
        this.perPage = 10
        this.skip = 0
    }
}

decorate(WithholdingListStore, {
    withholdings: observable,
    isLoading: observable,
    totalPages: observable,
    currentPage: observable,
    perPage: observable,
    fetch: action,
    processData: action,
    moveToPage: action,
    clear: action
})

export default new WithholdingListStore();
