import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Utilities from 'Utilities'
import 'Styles/MobileCard.scss'
import ActionButtons from './ActionButtons'

// Tarjeta usada en la version de movil o tablet.
const WithholdingCard = ({ withholding }) => {
    const { t } = useTranslation();

    return (
        <Col xs={12} sm={12} md={12} lg={12} className="mb-3 ">
            <div className="border p-3 shadow">
                <span className="s-card-head-text">{t('provider')}: </span><span>{withholding.Provider.BusinessName}</span><br />
                <div className="mt-3 s-card-details">
                    <span>{t('correlative') + ': '}
                        <span>{withholding.Correlative}</span>
                    </span><br />
                    <span>{t('date') + ': '}
                        <span>{Utilities.TransformDate(withholding.Date)}</span>
                    </span><br />
                    <span>{t('txt_generated_date') + ': '}
                        <span>
                            {withholding.DateGenerate === "2000-01-01T00:00:00"
                                ? t('txt_not_generated_yet')
                                : Utilities.TransformDate(withholding.DateGenerate)
                            }
                        </span>
                    </span><br />
                    <span>{t('impositive_period') + ': '}
                        <span>{withholding.ImpositivePeriod}</span>
                    </span><br />
                    <div className="action-buttons-card-container">
                        <ActionButtons
                            idWithholding={withholding.Id}
                            correlative={withholding.Correlative}
                            providerEmail={withholding.Provider.Email}
                        />
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default WithholdingCard