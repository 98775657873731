import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import LoadingSpinner from 'Components/LoadingSpinner'
import { Responsive } from 'responsive-react';
import { Table, Row } from 'react-bootstrap'
import { ListLayout } from 'Components/Layouts'
import { useTranslation } from 'react-i18next'
import ProviderCard from './ProviderCard'
import ActionButtons from './ActionButtons'

const ProvidersList = ({ providersStore }) => {
    const { t } = useTranslation();

    useEffect(() => {
        providersStore.fetch()
        return () =>{
            providersStore.clear()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ListLayout title='providers'>
                {providersStore.isLoading && <LoadingSpinner />}

                {!providersStore.isLoading && providersStore.providers &&
                    <>
                        {providersStore.providers.length === 0 && <h2>{t('not_providers_found')}</h2>}
                        {providersStore.providers.length &&
                            <>
                                <Responsive displayIn={["Laptop"]}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr className="text-center">
                                                <th>{t('legal_name')}</th>
                                                <th>{t('legal_identification')}</th>
                                                <th>{t('address')}</th>
                                                <th>{t('phone')}</th>
                                                <th>{t('email')}</th>
                                                <th>{t('vat_withholding_percentage')}</th>
                                                <th>{t('actions')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {providersStore.providers.map(provider => { 
                                                return <tr className="text-center">
                                                    <td>{provider.BusinessName}</td>
                                                    <td>{provider.Rif}</td>
                                                    <td>{provider.FiscalAddress}</td>
                                                    <td>{provider.PhoneNumber}</td>
                                                    <td>{provider.Email}</td>
                                                    <td className="text-right">{provider.WithholdingPercentageIVA}%</td>
                                                    <td><ActionButtons idProvider={provider.Id}/></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </Responsive>
                                <Responsive displayIn={["Mobile", "Tablet"]}>
                                    <Row>
                                        {providersStore.providers.map(provider => {
                                            return <ProviderCard provider={provider} />
                                        })}
                                    </Row>
                                </Responsive>
                            </>
                        }
                    </>
                }
            </ListLayout>
        </>
    )
}


export default inject('providersStore')(observer(ProvidersList))