/**
 * Notificaciones pre-escritas
 */
import GENERIC_NOTIFICATIONS from "./GenericNotifications"
import INVOICE_NOTIFICATIONS from "./InvoicenNotifications"


const AppNotifications = {
    ...GENERIC_NOTIFICATIONS,
    ...INVOICE_NOTIFICATIONS
}

export default AppNotifications