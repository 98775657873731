import requests, {AUTH_SERVICE_URL} from './Setup'

const RESOURCE = `${AUTH_SERVICE_URL}/Countries`

const Countries = {
  get: () =>
    requests.get(`${RESOURCE}/Get`).then(response => {
        if(response && response.status===200) return response.data
    }),
}

export default Countries