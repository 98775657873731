import React, { useEffect, useState } from 'react'
import LoadingSpinner from 'Components/LoadingSpinner'
import { ListLayout } from 'Components/Layouts'
import { useTranslation } from 'react-i18next'
import LargeScreenInvoicesList from './LargeScreen';
import SmallScreenInvoicesList from './SmallScreen';
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'
import apiService from 'Service'
import InvoicesListContext from './InvoicesListContext';
import useStores from 'hooks/useStores';


const InvoicesList = () => {
    const { t } = useTranslation();

    const [invoices, setInvoices] = useState<IInvoiceDocument[] | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const {invoicesStore} = useStores()

    useEffect(() => {
        async function LoadInvoices() {
            setIsLoading(true)
            setInvoices([])

            const result = await apiService.InvoiceDocuments.getAll(0, 500)
            setInvoices(result)
            setIsLoading(false)
        }

        LoadInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function deleteInvoice(invoice: IInvoiceDocument) {
        const success = await invoicesStore.deleteInvoice(invoice)
        if (success){
            setInvoices([...invoices!.filter(x=>x.Id !== invoice.Id)])
        }
    }


    if (isLoading) return <LoadingSpinner />

    if (invoices) {
        if (invoices.length === 0) return <h2>{t('not_invoices_found')}</h2>

        return <InvoicesListContext.Provider value={{invoices, deleteInvoice}}>
            <LargeScreenInvoicesList invoices={invoices} />
            <SmallScreenInvoicesList invoices={invoices} />
        </InvoicesListContext.Provider>
    }

    return <h2>{t('cannot_recover_list_of', { resource: t('invoices') })}</h2>
}

export default (() => {
    return <ListLayout title='invoices'>
        <InvoicesList />
    </ListLayout>
})