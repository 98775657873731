import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Provider, inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import EmailSendForm from 'Components/Forms/EmailSendForm'
import emailSendModalStore from 'stores/Components/emailSendModalStore'

const WithholdingEmailSendModal = inject('emailSendModalStore')(observer(({ emailSendModalStore })=>{

    const {t} = useTranslation()

    useEffect(()=>{
        return () =>{
            emailSendModalStore.closeModal()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function handleModalClose(){
        emailSendModalStore.closeModal()
    }
 
    return (<>
        <Modal
            show={emailSendModalStore.isVisible}
            onHide={handleModalClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('send_withholding_by_email')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmailSendForm/>
            </Modal.Body>
        </Modal>
    </>)
})) 


export default (()=><Provider emailSendModalStore={emailSendModalStore}>
    <WithholdingEmailSendModal/>
</Provider>)