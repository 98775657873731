import NotificationsTypes from '../NotificationsTypes'
import STANDARD_DISMISS from './StandardDismiss'


const GENERIC_NOTIFICATIONS = {
    NETWORK_ERROR: {
        type: NotificationsTypes.ERROR,
        message: 'connection_error',
    },
    SUCCESS_CREATED: {
        type: NotificationsTypes.SUCCESS,
        message: 'succesfull_created',
        dismiss: STANDARD_DISMISS
    },
    SUCCESS_ADDED: {
        type: NotificationsTypes.SUCCESS,
        message: 'succesfull_added',
        dismiss: STANDARD_DISMISS
    },
    SUCCESS_EDITED: {
        type: NotificationsTypes.SUCCESS,
        message: 'succesfull_edited',
        dismiss: STANDARD_DISMISS
    },
    SUCCESS_DELETED: {
        type: NotificationsTypes.WARNING,
        message: 'succesfull_deleted',
        dismiss: STANDARD_DISMISS
    },
    SUCCESS_EMAIL_SEND: {
        type: NotificationsTypes.SUCCESS,
        message: 'succesfull_email_send',
        dismiss: STANDARD_DISMISS
    },
    SUCCESS_SETTINGS_CHANGED: {
        type: NotificationsTypes.SUCCESS,
        message: 'success_settings_changed',
        dismiss: STANDARD_DISMISS
    },
    INTERNAL_SERVER_ERROR: {
        type: NotificationsTypes.ERROR,
        message: 'server_error',
        dismiss: STANDARD_DISMISS
    },
    NETWORK_TROUBLES: {
        type: NotificationsTypes.WARNING,
        message: 'connection_problems',
        dismiss: STANDARD_DISMISS
    },
    FAIL_EMAIL_SEND_RETRY: {
        type: NotificationsTypes.ERROR,
        message: 'failed_email_send_retry_later',
        dismiss: STANDARD_DISMISS
    },
    INVALID_CORRELATIVE: {
        type: NotificationsTypes.ERROR,
        message: 'new_correlative_must_be_greater_than_last_issued_document',
        dismiss: { ...STANDARD_DISMISS, duration: 5000 }
    }
}

export default GENERIC_NOTIFICATIONS