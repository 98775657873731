import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'Components/ErrorMessage'
import { inject, observer } from 'mobx-react'
import defaultValues from 'Constants/DefaultValues';
import apiService from 'Service'
import LoadingSpinner from 'Components/LoadingSpinner'
import * as yup from 'yup';

const AddMembershipOrCompany = ({ parentShowState,
    parentClose,
    isForMembership,
    isForDemo,
    membershipName = '',
    membershipsStore }) => {


    const validationSchema = yup.object().shape({
        Name: yup.string().required(),
        DocumentType: yup.string().required(),
        RIF: yup.string().numbers().min(8).max(9).required(),
        FiscalAddress: yup.string().required(),
        Address: yup.string().required(),
        Phone: yup.string().required(),
        Celular: yup.string().required(),
        PeopleContact: yup.string().required(),
        IdCountry: yup.string().required(),
    })

    const [countries, setCountries] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (countries.length === 0) {
            setIsLoading(true)
            apiService.Countries.get().then(countries => {
                if (countries) setCountries(countries)
            }).finally(() => setIsLoading(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (parentShowState === true) {

            if (isForDemo) membershipsStore.initProcess(defaultValues.demoMembership)
        }
    }, [parentShowState, membershipsStore, isForDemo])

    const { t } = useTranslation()
    const { register, handleSubmit, errors, setValue, reset } = useForm({ validationSchema: validationSchema });

    function handleModalClose() {
        parentClose()
    }

    function onSubmit(data) {
        if (isForDemo) {
            data.RIF = data.DocumentType + data.RIF
            membershipsStore.updateLocalMembershipState(data)
            membershipsStore.sendDemoRequest().then(() => {
                alert(t('demo_activated_successfully'))
                reset()
                handleModalClose()
            })
        }
    }

    return (

        <Modal show={parentShowState} onHide={handleModalClose}>



            <Form onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={membershipsStore.isLoading || isLoading}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isForDemo ? t('activate_demo') : isForMembership ? t('add_membership') : t('add_company')}</Modal.Title>
                    </Modal.Header>
                    {isLoading && <Modal.Body>
                        <LoadingSpinner />
                    </Modal.Body>}

                    {!isLoading && !countries.length &&
                        <Modal.Body><h2>{t('unable_to_load_countries')}</h2></Modal.Body>
                    }
                    {!isLoading && countries.length > 0 &&
                        <>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>{t('legal_name')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="Name"
                                    />
                                    <ErrorMessage error={errors?.Name} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('legal_identification')}</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <Form.Group controlId="DocumentType" className="mr-2">
                                                <Form.Control as="select"
                                                    name="DocumentType"
                                                    ref={register}>
                                                    <option value="J">J</option>
                                                    <option value="V">V</option>
                                                    <option value="G">G</option>
                                                    <option value="E">E</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </InputGroup.Prepend>
                                        <Form.Control type="text"
                                            name="RIF"
                                            ref={register} />
                                    </InputGroup>
                                    <ErrorMessage error={errors?.RIF} max={9} min={8} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('fiscal_address')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="FiscalAddress"
                                        onChange={e => setValue('Address', e.target.value)}
                                    />
                                    <ErrorMessage error={errors?.FiscalAddress} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('physical_address')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="Address"
                                    />
                                    <ErrorMessage error={errors?.Address} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('phone')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="Phone"
                                    />
                                    <ErrorMessage error={errors?.Phone} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('celular')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="Celular"
                                    />
                                    <ErrorMessage error={errors?.Celular} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('contact_person')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="PeopleContact"
                                    />
                                    <ErrorMessage error={errors?.PeopleContact} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t('country')}</Form.Label>
                                    <Form.Control as="select"
                                        name="IdCountry"
                                        ref={register}>
                                        {countries.map(country => {
                                            return <option
                                                key={country.IdCountry}
                                                value={country.IdCountry}>{country.CountryName}</option>
                                        })}
                                    </Form.Control>
                                    <ErrorMessage error={errors?.IdCountry} />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                {membershipsStore.isLoading &&
                                    <div className="mt-2 mb-4 d-flex justify-content-center">
                                        <LoadingSpinner />
                                    </div>
                                }
                                {!membershipsStore.isLoading && <>
                                    <Button variant="secondary" onClick={handleModalClose}>
                                        {t('cancel')}
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        {t('send_request')}
                                    </Button>
                                </>}
                            </Modal.Footer>
                        </>
                    }
                </fieldset>
            </Form>
        </Modal>
    )
}

export default inject('membershipsStore')(observer(AddMembershipOrCompany))