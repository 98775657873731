import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'


const RESOURCE = `/Providers`

const Providers = {
  getByRif: Rif =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/GetByRif/${Rif}`).then(response => {
      if (response && response.status === 200) return response.data
      if (response && response.status === 204) return false
    }).catch(err => {
      if(err && err.response && err.response.status === 404) return false
      throw err
  }),

  getById: Id =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/GetById/${Id}`).then(response => {
      if (response && response.status === 200) return response.data
    }),

  getAll: (initial, take) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/Get/${initial}/${take}`).then(response => {
      if (response && response.status === 200) return response.data
    }),

  add: newProviderData =>
    requests.post(`${membershipsStore.serviceUrl+RESOURCE}`, newProviderData).then(response => {
      if (response && response.status === 201) return response.data
    }),

  update: updatedData =>
    requests.put(`${membershipsStore.serviceUrl+RESOURCE}`, updatedData).then(response => {
      if (response && response.status === 200) return response.data
      return null
    }),

  delete: Id =>
    requests.del(`${membershipsStore.serviceUrl+RESOURCE}/Id`),
}

export default Providers