import React, { useEffect } from 'react'
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'Components/LoadingSpinner'
import ErrorMessage from 'Components/ErrorMessage'
import { observer } from 'mobx-react'
import * as yup from 'yup';
import useStores from 'hooks/useStores'


const IdentificationLookup = ({ nextStep }) => {
    const { t } = useTranslation();

    const { providersStore } = useStores()

    
    const validationSchema = yup.object().shape({
        DocumentType: yup.string().required(),
        Rif: yup.string().numbers().min(8).max(9).required(),
    });

    const { register, handleSubmit, errors, reset } = useForm({ validationSchema: validationSchema });
    const onSubmit = data => {
        const fullIdentification = data.DocumentType + data.Rif
        providersStore.identificationLookup(fullIdentification).then(res => {
            if (res === true) nextStep()
        })
    }

    useEffect(() => {
        reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="pb-4">
            <h5>{t('provider_lookup')}</h5>
            <fieldset disabled={providersStore.isLoading}>
                <Form.Group controlId="Rif">
                    <Form.Label>RIF:</Form.Label>
                    <br />
                    <em>
                        <small className="font-weight-bold">
                            {t('insert_provider_indentification_message')}
                        </small>
                    </em>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <Form.Group controlId="DocumentType" className="mr-2">
                                <Form.Control as="select"
                                    name="DocumentType"
                                    defaultValue={providersStore.provider.Rif[0]}
                                    ref={register}>
                                    <option value="J">J</option>
                                    <option value="V">V</option>
                                    <option value="G">G</option>
                                    <option value="E">E</option>
                                </Form.Control>
                            </Form.Group>
                        </InputGroup.Prepend>
                        <Form.Control type="text"
                            autoFocus
                            inputmode="numeric"
                            name="Rif" 
                            ref={register}
                            defaultValue={providersStore.provider.Rif.slice(1)} />
                    </InputGroup>
                    <ErrorMessage error={errors?.Rif} max={9} min={8}/>
                </Form.Group>
                {providersStore.isLoading
                    ? <LoadingSpinner />
                    : <>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                                <Button className="w-100" type="submit">{t('continue')}</Button>
                            </Col>
                        </Row>
                    </>
                }
            </fieldset>
        </Form>
    )
}

export default observer(IdentificationLookup)