import { decorate, observable, action } from 'mobx';
import Service from 'Service'
import uiStore from 'stores/uiStore'

class EmailSendModalStore {
    isVisible = false
    emails = []
    witholdingId = undefined
    isLoading = false

    openModal(witholdingId,initalEmails){
        this.emails = initalEmails
        this.witholdingId = witholdingId
        this.isVisible = true
    }

    closeModal(){
        this.isVisible = false
        this.emails = []
        this.witholdingId = undefined
        this.isLoading = false
    }

    setIsVisible(value){
        this.isVisible = value
    }

    setEmails(emails){
        this.emails = emails
    }

    setWitholdingId(value){
        this.witholdingId = value
    }

    send(){
        if(!this.witholdingId) throw new Error("WitholdingId not set to send")
        if(!this.emailsAreValid) throw new Error("There are no target emails in the array")
        this.isLoading = true;
        Service.Reports.sendEmailWithHoldingPdf(this.witholdingId, this.emails.toString()).then(()=>{
            uiStore.addNotification(uiStore.appNotifications.SUCCESS_EMAIL_SEND)
            this.closeModal()
        })
        .catch(e=>{
            uiStore.addNotification(uiStore.appNotifications.FAIL_EMAIL_SEND_RETRY)
        })
        .finally(()=>this.isLoading = false)

    }

    get emailsAreValid() {
        return this.emails.length > 0
    }
} 

decorate(EmailSendModalStore, {
    isVisible: observable,
    emails: observable,
    witholdingId: observable,
    isLoading: observable,
    openModal: action,
    closeModal: action,
    setIsVisible: action,
    setEmails: action,
    setWitholdingId: action,
    send: action
})

export default new EmailSendModalStore();
