import React, { useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'Components/LoadingSpinner'
import ErrorMessage from 'Components/ErrorMessage'
import DatePicker from 'Components/DatePicker'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom';
import useStores from 'hooks/useStores'


const WhithholdingInfoForm = ({ nextStep, previous }) => {
    const { t } = useTranslation();

    const { companyStore, withholdingsStore } = useStores()


    const validationSchema = yup.object().shape({
        Date: yup.date().required(),
        Correlative: yup.string().required(),
        ImpositivePeriod: yup.string().required()
    });

    const { register, handleSubmit, setValue, errors, control } = useForm({ validationSchema: validationSchema });
    const onSubmit = data => {
        withholdingsStore.updateWithholdingState(data)
        nextStep()
    };

    const history = useHistory()

    // Actualizar el correlativo y periodo imp la primera vez
    // solo cuando no vengan ya establecidos
    useEffect(() => {
        if (withholdingsStore.withholding.Correlative === undefined) {
            updateCorrelativeAndImpositive(withholdingsStore.withholding.Date)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handlePrevious() {
        previous();
    }

    function handleDateChange([value]) {
        updateCorrelativeAndImpositive(value)
        return value
    }

    function updateCorrelativeAndImpositive(ymdDate) {
        const date = new Date(ymdDate)
        let month = date.getMonth() + 1 //Enero es 0!
        if (month < 10) month = '0' + month
        const year = date.getFullYear()
        setValue('Correlative', `${year}${month}${formatCorrelative()}`)
        setValue('ImpositivePeriod', `${year}${month}`)
    }

    function formatCorrelative() {
        let withZeros = '00000000' + companyStore.selectedCompany.Settings.WithholdingCorrelative
        return withZeros.substr(withZeros.length - 8);
    }

    function goToSettings(){
        const confirmation = window.confirm(t('want_to_go_to_configuration_page'))
        if(confirmation) history.push()
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h5>{t('witholding_data')}</h5>
            <Form.Group controlId="whDate">
                <Form.Label>{t('date')}:</Form.Label>
                <Controller
                    as={DatePicker}
                    control={control}
                    name="Date"
                    onChange={handleDateChange}
                    defaultValue={withholdingsStore.withholding.Date} />
                <ErrorMessage error={errors?.Date} />
            </Form.Group>
            <Form.Group controlId="whCorrelative">
                <Form.Label>{t('correlative')}:</Form.Label>
                <div className="d-flex">
                    <div style={{width: "97%"}}>
                        <Form.Control type="text"
                            readOnly
                            defaultValue={withholdingsStore.withholding.Correlative}
                            name="Correlative"
                            ref={register} />
                    </div>
                    <div style={{width: "3%", cursor: "pointer"}} className="text-right my-auto">
                        <i class="fas fa-cog" onClick={goToSettings}></i>
                    </div>
                </div>
                <ErrorMessage error={errors?.Correlative} />
            </Form.Group>
            <Form.Group controlId="whImpositivePeriod">
                <Form.Label>{t('impositive_period')}:</Form.Label>
                <Form.Control type="text"
                    readOnly
                    name="ImpositivePeriod"
                    defaultValue={withholdingsStore.withholding.ImpositivePeriod}
                    ref={register} />
                <ErrorMessage error={errors?.ImpositivePeriod} />
            </Form.Group>
            {withholdingsStore.isLoading
                ? <LoadingSpinner />
                : <>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="order-1 order-sm-1 order-md-0 mb-1 mb-sm-1">
                            <Button className="w-100" variant="secondary" onClick={handlePrevious}>{t('back')}</Button>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                            <Button className="w-100" type="submit">{t('continue')}</Button>
                        </Col>
                    </Row>
                </>
            }
        </Form >
    )
}

export default observer(WhithholdingInfoForm)