import ErrorMessage from 'Components/ErrorMessage'
import LoadingSpinner from 'Components/LoadingSpinner'
import useStores from 'hooks/useStores'
import useTitle from 'hooks/useTitle'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Service from 'Service'
import * as yup from 'yup';

const Settings = () => {
    const { t } = useTranslation()
    useTitle(t('settings'))
    const { companyStore, uiStore } = useStores()
    const [isLoading, setIsLoading] = useState(false)

    const validationSchema = yup.object().shape({
        Correlative: yup.number().required(),
    });

    const { register, handleSubmit, errors } = useForm({ validationSchema: validationSchema });

    function onSubmit(data: any) {
        setIsLoading(true)
        Service.Settings.changeCorrelativeWithholding(data.Correlative).then((res) => {
            companyStore.updateCompanyCurrentCorrelative(data.Correlative)
            uiStore.addNotification(uiStore.appNotifications.SUCCESS_SETTINGS_CHANGED)
        }).catch((err) => {
            if (err.response.status === 400) {
                uiStore.addNotification(uiStore.appNotifications.INVALID_CORRELATIVE)
                return
            }
            if (err.response.status === 404) {
                throw new Error("Invalid correlative change attempt: company not found");
            }
            throw err
        }).finally(() => setIsLoading(false))
    }

    return <div>
        <h1>{t('settings')}</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={isLoading}>
                <Form.Group>
                    <Form.Label>{t('correlative')}</Form.Label>
                    <Form.Control type="number"
                        defaultValue={companyStore.selectedCompany.Settings.WithholdingCorrelative}
                        ref={register}
                        name="Correlative"
                    />
                    <ErrorMessage error={errors['Correlative']} />
                </Form.Group>
                <Form.Group>
                    <Button type="submit">
                        {t('save')}
                    </Button>
                    {isLoading && <LoadingSpinner/>}
                </Form.Group>
            </fieldset>
        </Form>
    </div>
}

export default Settings