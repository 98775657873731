import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Utilities from 'Utilities'
import 'Styles/MobileCard.scss'

const AmountCard = ({ amount, deleteAmountEntry }) => {
    const { t } = useTranslation();

    function handleDelete() {
        deleteAmountEntry(amount.Row)
    }

    return <Col xs={12} sm={12} md={12} lg={12} key={amount.Row} className="mb-1">
        <div className="border p-3">

            <div className="s-card-details">
                <Row>
                    <Col xs={10} sm={10}>
                        <span>
                            {t('base') + ': '}
                            <span>{Utilities.FormatCurrency(amount.TaxBase)}</span>
                        </span><br />
                        <span>
                            {t('vat_percentage') + ': '}
                            <span>{amount.TaxPorcentage}%</span>
                        </span><br />
                        <span>
                            {t('vat_amount') + ': '}
                            <span>{Utilities.FormatCurrency(amount.TaxAmmount)}</span>
                        </span><br />
                        <span>
                            {t('exempt_amount') + ': '}
                            <span>{Utilities.FormatCurrency(amount.TaxFree)}</span>
                        </span><br />
                        <span>
                            {t('total') + ': '}
                            <span>{Utilities.FormatCurrency(amount.TotalAmmount)}</span>
                        </span><br />
                    </Col>
                    <Col xs={2} sm={2} className="d-flex align-items-center">
                        <i style={{ cursor: "pointer" }} class="fas fa-minus-circle text-danger mt-2"
                            onClick={handleDelete}></i>
                    </Col>
                </Row>
            </div>
        </div>
    </Col>
}

export default observer(AmountCard)