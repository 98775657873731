import React from 'react'
import { Redirect} from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { LOGIN } from "../Constants/Routes";

const PrivateRoute = ({userStore, companyStore, Component, mustHaveSelectedCompany = false}) => {
        return(
            <div>
            {(userStore.currentUser && userStore.currentUser.token) === undefined || 
                (mustHaveSelectedCompany && companyStore.selectedCompany === undefined) ? 
                    <Redirect to={{ pathname: LOGIN}}/> : <div>{Component}</div>
            }
            </div>
        )
}

export default inject("userStore","companyStore")(observer(PrivateRoute))