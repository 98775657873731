const PURCHASE_LEDGER_FORMATS = {
    PDF: { value: 0, description: "PDF", extension: "pdf" },
    EXCEL: { value: 1, description: "Excel", extension: "xls" },
}

export function appendLedgerFormatExtensionToText(ledgerFormatValue: number, text: string) {
    const format = Object.values(PURCHASE_LEDGER_FORMATS).find(x=>x.value === ledgerFormatValue)
    if(!format) throw new Error("Invalid ledger format: "+ledgerFormatValue)
    return `${text}.${format.extension}`;
}

export default PURCHASE_LEDGER_FORMATS