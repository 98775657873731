import React from 'react'
import { Button, Form, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup';
import Moment from 'moment'
import ErrorMessage from 'Components/ErrorMessage'


const validationSchema = yup.object().shape({
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
});

const Filter = inject("txtStore")(observer(({ txtStore: store }) => {
    const defaultDateFrom = Moment().startOf('month').format('YYYY-MM-DD')
    const defaultDateTo = Moment().endOf('month').format('YYYY-MM-DD')

    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm({ validationSchema: validationSchema })

    function onSubmit(data) {
        store.fetchTxts(data.dateFrom, data.dateTo)
    }

    return (<Form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={store.isFetching}>
            <Form.Row>
                <Col xs={12} md={4}>
                    <Form.Group controlId="date_from">
                        <Form.Label>{t('date_from')}</Form.Label>
                        <Form.Control type="date"
                            name="dateFrom"
                            defaultValue={defaultDateFrom}
                            ref={register}
                        />
                        <ErrorMessage error={errors?.dateFrom} />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group controlId="date_fto">
                        <Form.Label>{t('date_from')}</Form.Label>
                        <Form.Control type="date"
                            name="dateTo"
                            defaultValue={defaultDateTo}
                            ref={register}
                        />
                        <ErrorMessage error={errors?.dateTo} />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4} className="d-flex align-items-end">
                    <Form.Group className="w-100">
                        <Button className="w-100" type="submit">{t('search')}</Button>
                    </Form.Group>
                </Col>
            </Form.Row>
        </fieldset>
    </Form>)
}))

export default Filter