import React from 'react'
import { Col, Accordion, Card } from 'react-bootstrap'
import Header from './Header'
import Body from './Body'

// Base para tarjeta usada en la version de movil o tablet.
const SAccordion = ({children, xs=12, sm=12, md=4, lg=4}) => {

    return (
        <Col xs={xs} sm={sm} md={md} lg={lg} className="mb-3">
            <Accordion>
                <Card>
                    {children}
                </Card>
            </Accordion>
        </Col>
    )
}

SAccordion.Header = Header
SAccordion.Body = Body

export default SAccordion