import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'

const RESOURCE = `/Settings`

const Reports = {
  //responseType: 'blob' necesario para la interpretación correcta del PDF
  changeCorrelativeWithholding: (newCorrelative: number) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/ChangeCorrelativeWithholding/${newCorrelative}`).then(response => {
      if (response && response.status === 200) return response.data
    }),
}

export default Reports 