import React, { useEffect } from 'react'
import { Col, Form, Button } from 'react-bootstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import settingsStore from './store'
import { Provider, inject, observer } from 'mobx-react'
import LoadingSpinner from 'Components/LoadingSpinner'
import useTitle from 'hooks/useTitle'

const validationSchema = yup.object().shape({
    Correlative: yup.string().required(),
});



const Settings = inject("settingsStore")(observer(({settingsStore}) => {
    const {t} = useTranslation()
    const { register, handleSubmit } = useForm({ validationSchema: validationSchema })

    const title = t('company_settings')
    useTitle(title)

    useEffect(()=>{
        settingsStore.loadSettings()
        settingsStore.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function onSubmit(data){

    }

    if(settingsStore.isLoading){
        return <LoadingSpinner/>
    }

    return (<>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <Form.Row>
                    <Col>
                        <Form.Group controlId="correlative">
                            <Form.Label>{t('correlative')}</Form.Label>
                            <Form.Control 
                                type="text"
                                ref={register}
                                name="Correlative"/>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Button type="submit">{t('update')}</Button>
            </fieldset>
        </Form>
    </>)
})) 

export default (() =>{
    return(
        <Provider settingsStore={settingsStore}><Settings/></Provider>
    )
})