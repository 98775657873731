import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle'
import { Row, Col } from 'react-bootstrap'
import Counters from './Counters'
import LicenceInfo from './LicenceInfo'
import QuickActions from './QuickActions'
import { Responsive } from 'responsive-react'
import LoadingSpinner from 'Components/LoadingSpinner'

const Dashboard = ({ authStore, dashboardStore }) => {
    const { t } = useTranslation();
    useTitle('dashboard')

    useEffect(() => {
        dashboardStore.fetchData()
        return () => {
            dashboardStore.reset()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <h4>{t('dashboard')}</h4>
            {/* Acciones Rapidas, solo tablet y movil */}
            <Responsive displayIn={['Mobile', 'Tablet']}>
                <div className="py-3">
                    <QuickActions />
                </div>
            </Responsive>

            <Row>
                {dashboardStore.isLoading &&
                    <Col xs={12} sm={12} md={12} lg={8}>
                        <LoadingSpinner />
                    </Col>
                }
                {!dashboardStore.isLoading &&
                    <Col xs={12} sm={12} md={12} lg={8}>
                        <Row>
                            {/* Avisos */}
                            <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                                <div style={{ height: "150px" }} className="w-100 bg-danger"></div>
                            </Col>

                        </Row>
                        <Row>

                            {/* Contadores */}

                            <Col xs={12} sm={12} md={12} lg={4} >
                                {!dashboardStore.data &&
                                    <h3 className="text-center">{t('error_loading_counters')}</h3>
                                }
                                {dashboardStore.data &&
                                    <Counters data={dashboardStore.data.TotalsCounter} />
                                }
                            </Col>
                            {/* Compradores */}
                            {/* <Col xs={12} sm={12} md={12} lg={4} >
                                <Fidelity data={dashboardStore.data.Fidelity}/>
                            </Col> */}
                            {/* Fidelidad */}
                            <Col xs={12} sm={12} md={12} lg={4} >
                                <div style={{ height: "350px" }} className="w-100 bg-warning"></div>
                            </Col>
                        </Row>
                    </Col>
                }
                {/* Licencia */}
                <Col xs={12} sm={12} md={12} lg={4} >
                    <LicenceInfo />
                </Col>
            </Row>
        </div>
    )
}

export default inject('authStore', 'dashboardStore')(observer(Dashboard))