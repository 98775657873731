import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import {
    ProviderInformationForm,
    IdentificationLookup,
    InvoiceInformation,
    AmountsForm
} from 'Components/Forms'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle'

const STEPS = {
    PROVIDER_LOOKUP: 1,
    PROVIDER_INFORMATION: 2,
    INVOICE_INFORMATION: 3,
    AMOUNTS_LIST: 4,
    ADD_AMOUNT: 5
}

const InvoiceRegister = ({ providersStore,invoicesStore }) => {
    const { t } = useTranslation();
    useTitle('registering_invoice')

    useEffect(() => {
        providersStore.initProcess()
        invoicesStore.initProcess()
        return () => {
            // Limpiar estados al salir
            providersStore.clear()
            invoicesStore.clear()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [step, setStep] = useState(STEPS.PROVIDER_LOOKUP)

    function nextStep() {
        switch (step) {
            case STEPS.PROVIDER_LOOKUP:
                setStep(STEPS.PROVIDER_INFORMATION)
                break;
            case STEPS.PROVIDER_INFORMATION:
                setStep(STEPS.INVOICE_INFORMATION)
                break;
            case STEPS.INVOICE_INFORMATION:
                setStep(STEPS.AMOUNTS_LIST)
                break;
            case STEPS.AMOUNTS_LIST:
                sendData()
                break;
            default:
                break;
        }
        if(step !== 4){
            window.scrollTo(0,1)
        }
    }

    function sendData(){
        invoicesStore.postNewCompleteDocument().then(res=>{
            if(res){
                alert(t('invoice_registered_successfully'))
                window.location.reload()
            }
        })
    }

    function previous() {
        switch (step) {
            case STEPS.PROVIDER_INFORMATION:
                providersStore.resetProcess()
                setStep(STEPS.PROVIDER_LOOKUP)
                break;
            case STEPS.INVOICE_INFORMATION:
                setStep( STEPS.PROVIDER_INFORMATION)
                break;
            case STEPS.AMOUNTS_LIST:
                setStep(STEPS.INVOICE_INFORMATION)
                break;
            default:
                break;
        }
        window.scrollTo(0,1)
    }

    return (
        <div className="mb-4 mt-2">

            <h3 className="text-center">{t('registering_invoice')}</h3>
            {step === STEPS.PROVIDER_LOOKUP &&
                <IdentificationLookup 
                    nextStep={nextStep} />
            }

            {step === STEPS.PROVIDER_INFORMATION &&
                <ProviderInformationForm 
                    nextStep={nextStep} 
                    previous={previous} />
            }

            {step === STEPS.INVOICE_INFORMATION &&
                <InvoiceInformation
                    nextStep={nextStep}
                    previous={previous}
                />}

            {step === STEPS.AMOUNTS_LIST &&
                <AmountsForm
                    nextStep={nextStep}
                    previous={previous} />}

            <Prompt
                when={step > STEPS.PROVIDER_LOOKUP}
                message={t('do_you_really_want_to_quit_the_process')}
            />

        </div>
    )
}

export default inject("providersStore","invoicesStore")(observer(InvoiceRegister))