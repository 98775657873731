import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Utilities from 'Utilities'
import 'Styles/MobileCard.scss'
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'
import ActionButtons from './ActionButtons'


type InvoiceCardPropsType = {
    invoice: IInvoiceDocument,

}
// Tarjeta usada en la version de movil o tablet.
const InvoiceCard = ({ invoice }: InvoiceCardPropsType) => {
    const { t } = useTranslation();

    return (
        <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
            <div className="border p-3 shadow">
                <span className="s-card-head-text">{t('invoice')}: </span><span>{invoice.InvoiceNumber}</span><br />
                <div className="mt-3 s-card-details">
                    <span>{t('provider') + ': '}
                        <span>{invoice.BusinessName}</span>
                    </span><br />
                    <span>{t('total_amount') + ': '}
                        <span>{Utilities.FormatCurrency(invoice.Total)}</span>
                    </span><br />
                    <span>{t('control_number') + ': '} <span>{invoice.ControlNumber}
                    </span>
                    </span><br></br>
                    {invoice.Serie && <>
                        <span>{t('serie') + ': '}
                            <span>{invoice.Serie}</span>
                        </span><br></br></>
                    }
                    <span>{t('emit_date') + ': '}
                        <span>{Utilities.TransformDate(invoice.EmitDate)}</span>
                    </span><br></br>
                </div>
                <div className="action-buttons-card-container" >
                    <ActionButtons invoice={invoice} />
                </div>

            </div>
        </Col>
    )
}

export default InvoiceCard