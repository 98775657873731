import { observable, action, decorate } from 'mobx';
import apiService from '../Service';
import userStore from './userStore';
import configurationStore from './configurationStore';
import companyStore from './companyStore';
import membershipsStore from './companyStore';
import dashboardStore from './dashboardStore'

class AuthStore {
  inProgress = false;
  errors = {
    invalidLogin: false,
    passwordIncomplete: false
  };

  check(email){
    this.inProgress = true;
    this.errors = {};
    return apiService.Account.emailValidate(email)
      .then(resp => { 
        if(resp.status === 404) return false
        return false
      })
      .catch(action((err) => {
        if(err && err.response && err.response.status && err.response.status === 409){
          return true
        }
        if(err && err.response && err.response.status && err.response.status === 404) return false
        
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  login(Email, Password, remember = false) {
    this.inProgress = true;
    this.errors = {};
    //this.inProgress = false;
    return apiService.Account.login(Email, Password)
      .then(resp => {
        if (resp.status === 200){
          remember ? configurationStore.setRemember(Email) : configurationStore.setRemember('')
          return this.setupStores(resp.data)
        }
      })
      .catch(action((err) => {
        if(err.response && (err.response.status === 404 || err.response.status === 400)){
          this.errors.invalidLogin = true;
          throw new Error('invalid login')
        }
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  setupStores(data){
    userStore.setCurrentUser(data.User)
    companyStore.setOwnCompanies(data.OwnerCompanies)
    companyStore.setThirdPartyCompanies(data.ThirdCompanies)
    //configurationStore.setOperationsType(data.OperationTypes)
    //configurationStore.setTaxes(data.Taxes)
    //companyStore.setSelectedCompany(data.OwnerCompanies[0].Companies[0])
    
  }

  register(Email, Password, ConfirmPassword) {
    this.inProgress = true;
    this.errors = {};
    return apiService.Account.register(Email, Password, ConfirmPassword)
      .then(response => {
        if(response.status === 200) return this.login(Email, Password)
      })
      .catch(action((err) => {
        if(err.response && (err.response.status === 404)){
          this.errors.invalidLogin = true;
         return new Error('Error de conexion')
        }
        if(err.response && (err.response.status === 400)){
          this._error= err.response.data.ModelState;
          this.errors.passwordIncomplete = true;
         return new Error('La contraseña no cumple los requisitos minimos. Debe tener al menos una mayuscula, un numero y un caracter especial')
        }
        // this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  logout() {
    companyStore.forgetAll()
    configurationStore.forgetAll()
    userStore.forgetAll();
    membershipsStore.forgetAll()
    dashboardStore.reset()
    return Promise.resolve();
  }
}

decorate(AuthStore,{
  inProgress: observable,
  errors: observable,
  login: action,
  register: action,
  logout: action,
})

export default new AuthStore();
