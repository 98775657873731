import React from 'react'
import { Table, Row } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Responsive } from 'responsive-react'
import AmountRow from './AmountRow'
import AmountCard from './AmountCard'
import useStores from 'hooks/useStores'

const AmountsSection = () => {
    const { t } = useTranslation();

    const {invoicesStore} =useStores()

    function deleteAmountEntry(row) {
        const res = window.confirm(t('do_you_really_want_to_remove_this_amount?'))
        if (res) invoicesStore.deleteAmount(row)
    }

    if (invoicesStore.amounts.length) {
        return <>
            <b><p>{t('registered_amounts')}</p></b>
            <Responsive displayIn={["Laptop"]}>
                <Table striped bordered hover className="text-right">
                    <thead>
                        <tr className="text-center">
                            <th>{t('base')}</th>
                            <th>{t('vat_percentage')}</th>
                            <th>{t('vat_amount')}</th>
                            <th>{t('exempt_amount')}</th>
                            <th>{t('total')}</th>
                            <th>{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoicesStore.amounts.map(amount => {
                            return <AmountRow
                                amount={amount}
                                deleteAmountEntry={deleteAmountEntry} />
                        })}
                    </tbody>
                </Table>
            </Responsive>
            <Responsive displayIn={["Mobile", "Tablet"]}>
                <Row>
                    {invoicesStore.amounts.map(amount => {
                        return <AmountCard
                            amount={amount}
                            deleteAmountEntry={deleteAmountEntry} />
                    })}
                </Row>
            </Responsive>
        </>
    }

    return <div className="py-4">
        <h4 className="text-muded text-center">{t('no_amounts_registered_yet')}</h4>
    </div>
}

export default observer(AmountsSection)