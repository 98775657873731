import React from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'Components/LoadingSpinner'
import ErrorMessage from 'Components/ErrorMessage'
import { observer } from 'mobx-react'
import * as yup from 'yup';
import useStores from 'hooks/useStores'


const ProviderInformationFormNew = ({ nextStep, previous }) => {
    const { t } = useTranslation();

    const { providersStore } = useStores()


    const validationSchema = yup.object().shape({
        Rif: yup.string(),
        BusinessName: yup.string().required(),
        FiscalAddress: yup.string().required(),
        Email: yup.string().email(),
        PhoneNumber: yup.string().numbers(),
        WithholdingPercentageIVA: yup.number().min(0).max(100),
        WithholdingPercentageISLR: yup.number().min(0).max(100),
    });

    const { register, handleSubmit, errors, formState } = useForm({ validationSchema: validationSchema });
    const {dirty} = formState

    const onSubmit = data => {
        if (providersStore.providerExists) {
            if (dirty) {
                providersStore.updateProvider(data).then(res => {
                    if (res) nextStep()
                })
            }else{
                nextStep()
            }
        } else {
            providersStore.addProvider(data).then(res => {
                if (res) nextStep()
            })
        }
    };


    function handlePrevious() {
        previous();
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="pb-4">
            <h5>{t('provider_data')}</h5>
            <fieldset disabled={providersStore.isLoading}>
                <Form.Group controlId="prRif2">
                    <Form.Label>RIF:</Form.Label>
                    <Form.Control type="text"
                        readOnly
                        name="Rif"
                        ref={register}
                        defaultValue={providersStore.provider.Rif} />
                    <ErrorMessage error={errors?.Rif} />
                </Form.Group>
                <Form.Group controlId="prBusinessName">
                    <Form.Label>Razon Social:</Form.Label>
                    <Form.Control type="text"
                        name="BusinessName"
                        ref={register}
                        defaultValue={providersStore.provider.BusinessName} />
                    <ErrorMessage error={errors?.BusinessName} />
                </Form.Group>
                <Form.Group controlId="prFiscalAddress">
                    <Form.Label>Dirección:</Form.Label>
                    <Form.Control type="text"
                        name="FiscalAddress"
                        ref={register}
                        defaultValue={providersStore.provider.FiscalAddress} />
                    <ErrorMessage error={errors?.FiscalAddress} />
                </Form.Group>
                <Form.Group controlId="prEmail">
                    <Form.Label>{t('email')}:</Form.Label>
                    <Form.Control type="email"
                        name="Email"
                        ref={register}
                        defaultValue={providersStore.provider.Email} />
                    <ErrorMessage error={errors?.Email} />
                </Form.Group>
                <Form.Group controlId="prPhoneNumber">
                    <Form.Label>Número de teléfono:</Form.Label>
                    <Form.Control type="text"
                        name="PhoneNumber"
                        ref={register}
                        defaultValue={providersStore.provider.PhoneNumber} />
                    <ErrorMessage error={errors?.PhoneNumber} />
                </Form.Group>
                <Form.Group controlId="prWithholdingPercentageIVA">
                    <Form.Label>Porcentaje de Retencion:</Form.Label>
                    <Form.Control type="number"
                        min="0"
                        max="100"
                        name="WithholdingPercentageIVA"
                        ref={register}
                        defaultValue={providersStore.provider.WithholdingPercentageIVA || 0} />
                    <ErrorMessage error={errors?.WithholdingPercentageIVA} />
                </Form.Group>
                {/* <Form.Group controlId="prWithholdingPercentageISLR" >
                    <Form.Label>Porcentaje ISLR:</Form.Label>
                    <Form.Control type="number"
                        min="0"
                        max="100"
                        name="WithholdingPercentageISLR"
                        ref={register}
                        defaultValue={providersStore.provider.WithholdingPercentageISLR || 0} />
                    <ErrorMessage error={errors?.WithholdingPercentageISLR} />
                </Form.Group> */}
                <Form.Group controlId="SpecialTaxpayer">
                    <Form.Check
                        type={'checkbox'}
                        id={'SpecialTaxtpayer'}
                        name='SpecialTaxpayer'
                        label={'Agente de retención Especial'}
                        ref={register}
                        defaultChecked={providersStore.provider.SpecialTaxpayer}
                    />
                </Form.Group>
                {providersStore.isLoading
                    ? <LoadingSpinner />
                    : <>
                        <Row>
                            {previous &&
                                <Col xs={12} sm={12} md={6} lg={6} className="order-1 order-sm-1 order-md-0 mb-1 mb-sm-1">
                                    <Button className="w-100" variant="secondary" onClick={handlePrevious}>{t('back')}</Button>
                                </Col>
                            }
                            <Col xs={12} sm={12} md={previous ? 6 : 12} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                                <Button className="w-100" type="submit">{previous ? t('continue') : t('update')}</Button>
                            </Col>
                        </Row>
                    </>
                }
            </fieldset>
        </Form>
    )
}

export default observer(ProviderInformationFormNew)