import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import SAcordion from 'Components/SAcordion'
import Utilities from 'Utilities';
import IInvoiceForDocumentSelection from 'interfaces/app/IInvoiceForDocumentSelection';

type DocumentCardPropsType = {
    document: IInvoiceForDocumentSelection,
    handleSelectionChange: (document: IInvoiceForDocumentSelection) => void
}

// Tarjeta usada en la version de movil o tablet.
const DocumentCard = ({ document, handleSelectionChange}: DocumentCardPropsType) => {
    const { t } = useTranslation();

    return (
        <SAcordion sm={6}>
            <SAcordion.Header>
                <Row>
                    <Col xs={10} sm={10} md={10} lg={10}>
                        <Row>
                            <span><b>{t('invoice_number')}</b>: {document.Invoice.InvoiceNumber}</span><br></br>
                            <span><b>{t('total_amount')}</b>: {document.CalculatedTotalAmount}</span>
                        </Row>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} className="border-left justify-content-center">
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <input type="checkbox" checked={document.Selected} onChange={() => { handleSelectionChange(document) }} />
                        </div>
                    </Col>
                </Row>
            </SAcordion.Header>
            <SAcordion.Body>
                <span><b>{t('control_number')}</b>: {document.Invoice.ControlNumber}</span><br></br>
                <span><b>{t('serie')}</b>: {document.Invoice.Serie}</span><br></br>
                <span><b>{t('emit_date')}</b>: {Utilities.TransformDate(document.Invoice.EmitDate)}</span><br></br>
            </SAcordion.Body>
        </SAcordion>
    )
}

export default DocumentCard