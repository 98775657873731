import { decorate, observable, action } from 'mobx';
import { store as NotificationsStore } from 'react-notifications-component';
import AppNotifications from 'Constants/AppNotifications/AppNotifications'
import i18n from 'i18n'

class uiStore {
  appNotifications = AppNotifications
  /* constructor(){
    window.onresize = action(()=>this.handleWindowResize())
  } */

  isSidebarVisible = (window.innerWidth > 992)
  
  /* handleWindowResize(){
    if(!(window.innerWidth > 768) && this.isSidebarVisible){
      this.toggleSidebar()
    }
  } */

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible
    this.lockBodyScroll()
  }

  closeSidebar(){
    this.isSidebarVisible = false;
    this.lockBodyScroll()
  }

  lockBodyScroll(){
    if (!(window.innerWidth > 992)) {
      window.document.body.style.overflow = this.isSidebarVisible ? "hidden" : "auto"
    }
  }
  
  addNotification(notification) {
    NotificationsStore.addNotification({
      insert: "top",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      ...notification,
      message: i18n.t('notifications:'+notification.message),
    })
  }

}

decorate(uiStore, {
  isSidebarVisible: observable,
  toggleSidebar: action,
  addNotification: action,
  closeSidebar: action
})



export default new uiStore();
