import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import 'Styles/MobileCard.scss'
import ActionButtons from './ActionButtons'

// Tarjeta usada en la version de movil o tablet.
const ProviderCard = ({ provider }) => {
    const { t } = useTranslation();

    return (
        <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
            <div className="border p-3 shadow">
                <span className="s-card-head-text">{t('legal_name')}: </span><span>{provider.BusinessName}</span><br />
                <div className="mt-3 s-card-details">
                    <span>{t('legal_identification')+': '} 
                        <span>{provider.Rif}</span>
                    </span><br />
                    <span>{t('address')+': '} 
                        <span>{provider.FiscalAddress}</span>
                    </span><br/>
                    <span>{t('phone')+': '} 
                        <span>{provider.PhoneNumber}</span>
                    </span><br></br>
                    <span>{t('email')+': '} 
                            <span>{provider.Email}</span>
                    </span><br></br>
                    <span>{t('vat_withholding_percentage')+': '} 
                            <span>{provider.WithholdingPercentageIVA}%</span>
                    </span><br></br>
                    <div className="mt-1 text-center" style={{ fontSize: '20px' }}>
                        <ActionButtons
                            idProvider={provider.Id}
                        />
                    </div>
                </div> 
            </div>
        </Col>
    )
}

export default ProviderCard