import React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from 'Components/LoadingSpinner'
import useTitle from 'hooks/useTitle'
import { inject, observer } from 'mobx-react'
import Table from './Table'

const ListRender = inject("txtStore")(observer(({ txtStore: store }) => {
    const { t } = useTranslation()
    useTitle('txt_file')

    if(store.isFetching) return <LoadingSpinner />

    if(store.txts.length === 0) {
        return <h3 className="text-center">{t('no_txt_files_on_that_data_range')}</h3>
    }

    return <Table/>
}))

export default ListRender