import React from 'react'
import { useForm } from "react-hook-form";
import { Modal, Form, Button } from 'react-bootstrap'


const InvitationModal = ({ toCompany, parentShowState, parentClose }) => {
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {

    };

    function hanldeModalClose(){
        parentClose()
    }

    return (
        <Modal show={parentShowState} onHide={hanldeModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Invitar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="ivEmail">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control type="email" required name="email" ref={register} />
                    </Form.Group>
                    <Button type="submit">Invitar</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hanldeModalClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default InvitationModal