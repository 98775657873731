import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { PROVIDERS_EDIT } from 'Constants/Routes'

// Tarjeta usada en la version de movil o tablet.
const ActionButtons = ({idProvider}) => {
    const { t } = useTranslation();

    return (
        <>
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-view`}>
                        {t('edit')}
                    </Tooltip>
                }
            >
                <Link to={PROVIDERS_EDIT+idProvider}>
                    <i className="fas fa-edit btn"/>
                </Link>
            </OverlayTrigger>
        </>
    )
}

export default ActionButtons