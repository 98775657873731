import React from 'react'
import { Link } from 'react-router-dom'
import { COMPANY_SELECTION } from "../Constants/Routes";
import InvitationCard from '../Components/InvitationCard'
import { inject, observer } from 'mobx-react'
import { Row, Col } from 'react-bootstrap'

const PendingInvitations = ({ CommonStore }) => {

    return (
        <div>
            <h1>Invitations page</h1>
            <Link to={COMPANY_SELECTION}>Saltar</Link>
            {CommonStore.invitations.length ?
                <Row>
                    {CommonStore.invitations.map(invitation => {
                        return <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-3">
                                    <InvitationCard invitation={invitation} />
                                </Col>
                    })}
                </Row>
                :
                <h2>No hay invitaciones pendientes por revisar</h2>
            }
        </div>
    )
}

export default inject("CommonStore")(observer(PendingInvitations))