import React, { useState } from 'react'
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoadingSpinnerAlt from 'Components/LoadingSpinnerAlt';
import InvoicesListContext from './InvoicesListContext';


type ActionButtons = {
    invoice: IInvoiceDocument,
}

const ActionButtons = ({ invoice }: ActionButtons) => {
    const { t } = useTranslation();

    const [isDeleting, setisDeleting] = useState(false)

    const { deleteInvoice } = React.useContext(InvoicesListContext)

    async function handleDelete() {
        if (invoice.IdWithholding) {
            alert(t('this_invoice_is_part_of_a_withholding_and_cannot_be_deleted'))
            return
        }
        const confirmation = window.confirm(t('confirm_deletion'))
        if (confirmation) {
            try {
                setisDeleting(true)
                await deleteInvoice(invoice)

            } finally {
                setisDeleting(false)
            }
        }
    }
    return (
        <>
            {!invoice.IdWithholding && <>
                {isDeleting && <div className="d-inline-block action-button-loading-container">
                    <LoadingSpinnerAlt />
                </div>}
                {!isDeleting && <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-view`}>
                            {t('delete')}
                        </Tooltip>
                    }
                >
                    <i className="fas fa-trash cursor-pointer" onClick={handleDelete} />
                </OverlayTrigger>}
            </>
            }
        </>
    )
}

export default ActionButtons