import React from 'react'
import { Row, Col, } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { LOGO_URL } from 'Constants/Media'
import { inject, observer } from 'mobx-react'

const LicenceInfo = ({ companyStore, membershipsStore }) => {
    const { t } = useTranslation()

    return (
        <div >
            <div className="h-100 p-2 border shadow ">
                <div className="text-center">
                    <img src={LOGO_URL} alt="logo" height="75px" />
                </div>
                <div className="border p-2 mt-2">
                    <span className="text-muted">{t('membership')}</span><br />
                    <span className="ml-3">{membershipsStore.currentMembership.BusinessName}</span><br />
                    <span className="text-muted">{t('company')}</span><br />
                    <span className="ml-3">{companyStore.selectedCompany.BusinessName}</span><br />
                </div>
                <Row className="mt-2 text-center">
                    <Col xs={12} sm={4} md={4} lg={12} className="mt-1">
                        <div className="p-1 border">
                            <span className="text-muted">{t('licence_type')}</span><br />
                            <span>{membershipsStore.currentMembership.LicenseType}</span>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={12} className="mt-1">
                        <div className="p-1 border">
                            <span className="text-muted">{t('compies_count')}</span><br />
                            <span>{membershipsStore.currentMembership.Companies.length}</span>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={12} className="mt-1">
                        <div className="p-1 border">
                            <span className="text-muted">{t('licensetype')}</span><br />
                            <span>{membershipsStore.currentMembership.LicenseType}</span>
                        </div>
                    </Col>
                </Row>
                <Row >
                    <Col xs={12}>
                        <div className="border p-2 mt-2">
                            <span className="text-muted">{t('days_left') + ': '}</span>
                            <span>{membershipsStore.currentMembership.LicenseRemainingDays}</span>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="border p-2 mt-2">
                            <span className="text-muted">{t('pending_renovation') + ': '}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default inject('companyStore', 'membershipsStore')(observer(LicenceInfo))