import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import stores from './stores'
import { Provider } from 'mobx-react'
import './yup-custom'
import StoresContext from 'Contexts/StoresContext'
import RootStore from 'stores/RootStore'


function setAppAndVersionOnLs() {
  localStorage.setItem('84929976-3de4-4670-8434-d97312b3256c', process.env.REACT_APP_NAME)
  localStorage.setItem('60318a0c-69cc-474a-bc19-010507c1127f', process.env.REACT_APP_VERSION)
}

const lsAppName = localStorage.getItem('84929976-3de4-4670-8434-d97312b3256c')
const lsAppVersion = localStorage.getItem('60318a0c-69cc-474a-bc19-010507c1127f')

if (lsAppName === null || lsAppVersion === null) {
  stores.authStore.logout()
  setAppAndVersionOnLs()
}
if (process.env.REACT_APP_NAME !== lsAppName || process.env.REACT_APP_VERSION !== lsAppVersion) {
  stores.authStore.logout()
  setAppAndVersionOnLs()
}

ReactDOM.render(
  <StoresContext.Provider value={RootStore}>
    <Provider {...stores}>
      <React.StrictMode>
        <Suspense fallback={<div></div>}>
          <App />
        </Suspense>
      </React.StrictMode>
    </Provider>
  </StoresContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

