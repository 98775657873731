import React from 'react'
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import './index.css'
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

export default (props) =>{
    let value = null
    if(props.value){
        value = new Date(props.value)
    }

    return <DateInput 
                    formatDate={date => {
                        return moment(date).format('DD/MM/yyyy')
                    }}
                    parseDate={str => {
                        return new Date(str)
                    }}
                    placeholder={"dd/mm/aaaa"}
                    locale="es" localeUtils={MomentLocaleUtils}
                    {...props}
                    value={value}
                />
}