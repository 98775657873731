import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import DocumentSelectionForm from './InvoiceSelectionForm/DocumentSelectionForm'
import WhithholdingInfoForm from './WhithholdingInfoForm'
import {
    ProviderInformationForm,
    IdentificationLookup
} from '../../../Components/Forms'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle'
import FinalOptions from './FinalOptions'
import WITHHOLDING_GENERATION_STEPS from './WithholdingGenerationSteps'
import useStores from 'hooks/useStores'


const WithholdingGeneration = () => {
    const { t } = useTranslation();
    useTitle('generating_withholding')
    const { providersStore, withholdingsStore } = useStores()

    const [step, setStep] = useState(WITHHOLDING_GENERATION_STEPS.IDENTIFICATION_LOOKUP)

    useEffect(() => {
        providersStore.initProcess()
        withholdingsStore.initProcess()
        return () => {
            // Limpiar estados al salir
            providersStore.clear()
            withholdingsStore.clear()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function nextStep() {
        switch (step) {
            case WITHHOLDING_GENERATION_STEPS.IDENTIFICATION_LOOKUP:
                setStep(WITHHOLDING_GENERATION_STEPS.PROVIDER_INFO_INPUT)
                break;
            case WITHHOLDING_GENERATION_STEPS.PROVIDER_INFO_INPUT:
                setStep(WITHHOLDING_GENERATION_STEPS.WITHHOLDING_INFO_INPUT)
                break;
            case WITHHOLDING_GENERATION_STEPS.WITHHOLDING_INFO_INPUT:
                setStep(WITHHOLDING_GENERATION_STEPS.INVOICES_SELECTION)
                break;
            case WITHHOLDING_GENERATION_STEPS.INVOICES_SELECTION:
                setStep(WITHHOLDING_GENERATION_STEPS.FINAL_OPTIONS)
                break;
            default:
                break;
        }
        window.scrollTo(0, 1)
    }

    function previous() {
        switch (step) {
            case WITHHOLDING_GENERATION_STEPS.PROVIDER_INFO_INPUT:
                providersStore.resetProcess()
                setStep(WITHHOLDING_GENERATION_STEPS.IDENTIFICATION_LOOKUP)
                break;
            case WITHHOLDING_GENERATION_STEPS.WITHHOLDING_INFO_INPUT:
                setStep(WITHHOLDING_GENERATION_STEPS.PROVIDER_INFO_INPUT)
                break;
            case WITHHOLDING_GENERATION_STEPS.INVOICES_SELECTION:
                setStep(WITHHOLDING_GENERATION_STEPS.WITHHOLDING_INFO_INPUT)
                break;
            default:
                break;
        }
        window.scrollTo(0, 1)
    }

    return (
        <div className="mb-4 mt-2">
            {step !== WITHHOLDING_GENERATION_STEPS.FINAL_OPTIONS &&
                <h3 className="text-center">{t('generating_withholding')}</h3>
            }

            {step === WITHHOLDING_GENERATION_STEPS.IDENTIFICATION_LOOKUP &&
                <IdentificationLookup
                    nextStep={nextStep} />
            }

            {step === WITHHOLDING_GENERATION_STEPS.PROVIDER_INFO_INPUT &&
                <ProviderInformationForm
                    nextStep={nextStep}
                    previous={previous} />
            }

            {step === WITHHOLDING_GENERATION_STEPS.WITHHOLDING_INFO_INPUT &&
                <WhithholdingInfoForm
                    previous={previous}
                    nextStep={nextStep} />
            }

            {step === WITHHOLDING_GENERATION_STEPS.INVOICES_SELECTION &&
                <DocumentSelectionForm
                    parentPrevious={previous}
                    nextStep={nextStep} />}

            {step === WITHHOLDING_GENERATION_STEPS.FINAL_OPTIONS &&
                <FinalOptions />
            }

            <Prompt
                when={step > 1 && step < 5}
                message={t('do_you_really_want_to_quit_the_process')}
            />

        </div>
    )
}

export default observer(WithholdingGeneration)