import * as yup from 'yup';

declare module 'yup' {
    interface StringSchema<T> {
        numbers(): StringSchema<T>;
    }
}


yup.setLocale({
    mixed: {
        default: 'validations:field_is_invalid',
        required: 'validations:field_is_required',
        oneOf: ({ values }) => ({ key: 'validations:must_be_one_of_following_values', values: { values } }),
        notOneOf: ({ values }) => ({ key: 'validations:must_not_be_one_of_following_values', values: { values } }),
        notType: 'validations:incorrect_type',
        defined: 'validations:must_be_defined'
    },
    string: {
        length: ({ length }) => ({ key: 'validations:characters_length_must_be_exactly', values: { length } }),
        min: ({ min }) => ({ key: 'validations:minimun_required_characters', values: { min } }),
        max: ({ max }) => ({ key: 'validations:maximun_allowed_characters', values: { max } }),
        matches: 'validations:must_match_expected_pattern_pattern',
        email: 'validations:must_be_an_email',
        url: 'validations:must_be_a_valid_url',
        uuid: 'validations:must_be_a_valid_uuid',
        trim: 'validations.must_be_a_trimed_string',
        lowercase: 'validations:must_be_lowercase',
        uppercase: 'validations:must_be_uppercase',
    },
    number: {
        min: ({ min }) => ({ key: 'validations:value_must_be_greater_or_equal', values: { min } }),
        max: ({ max }) => ({ key: 'validations:value_must_be_less_or_equal', values: { max } }),
        lessThan: ({ less }) => ({ key: 'validations:value_must_be_less_than', values: { less } }),
        moreThan: ({ more }) => ({ key: 'validations:value_must_be_more_than', values: { more } }),
        notEqual: ({ notEqual }) => ({ key: 'validations:value_must_not_be_equal_to', values: { notEqual } }),
        positive: 'validations:value_must_be_positive_number',
        negative: 'validations:value_must_be_negative_number',
        integer: 'validations:value_must_be_interger',
    },
    date: {
        min: ({ min }) => ({ key: 'validations:date_must_be_later', values: { min } }),
        max: ({ max }) => ({ key: 'validations:date_must_be_earlier', values: { max } }),
    },
    object: {
        noUnknown: ({ unknown }) => ({ key: 'validations:object_has_unspecified_key', values: { unknown } }),
    },
    array:{
        min: ({ min }) => ({ key: 'validations:array_must_have_minimun_items', values: { min } }),
        max: ({ max }) => ({ key: 'validations:array_must_have_maximun_items', values: { max } }),
    }
})


yup.addMethod<yup.StringSchema>(yup.string, 'numbers', function () {
    return this.matches(/^[0-9]*$/g, 'validations:field_numbers_only')
})

