export const LOGIN = '/'
export const APP = '/app'

export const DASHBOARD = APP + '/dashboard'
export const COMPANY_SELECTION = APP + '/companies/selection'
export const PENDING_INVITATIONS = APP + '/invitations/pending'

export const INVOICES_LIST = APP + '/invoices'
export const INVOICES_ADD = APP + '/invoices/add'
export const INVOICES_EDIT = APP + '/invoices/edit/:id'

export const PROVIDERS_LIST = APP + '/providers'
export const PROVIDERS_EDIT = APP + '/providers/edit/' //:id

export const WITHHOLDINGS_LIST = APP + '/withholdings/'
export const WITHHOLDINGS_GENERATION = APP + '/withholdings/generate'

export const REPORTS_PURCHASELEDGER = APP + '/reports/purchaseLedger'

export const COMPANY_SETTINGS = APP + '/companies/settings'

export const TXT_SECTION = APP + '/txt'
export const TXT_GENERATE = APP + '/txt/generate'

export const SETTINGS = APP + '/settings'