import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import { DASHBOARD, INVOICES_ADD } from "Constants/Routes";
import AddMembershipOrCompany from './AddMembershipOrCompany'
import InvitationModal from 'Components/Modals/InvitationModal'
import useTitle from 'hooks/useTitle'
import { useTranslation } from 'react-i18next'

const CompanySelection = ({ companyStore, authStore, history }) => {
    useTitle('company_selection')

    const { t } = useTranslation()

    // Datos para el control de la ventana modal de creacion de membresia/compañia
    const [showCompanyRegModal, setShowCompanyRegModal] = useState(false)
    const [modalIsForMembership, setModalIsForMembership] = useState(false)
    const [modalIsForDemo, setModalIsForDemo] = useState(false)
    const [modalMembershipName, setModalMembershipName] = useState('')
    

    //Control del modal de invitación
    const [showInvitationModal, setShowInvitationModal] = useState(false)
    const [inviteToCompany, setInviteToCompany] = useState('') // Id Compañia

    // Eventos modal de cracion de compañia/membresia
    function closeCompanyModal() {
        setShowCompanyRegModal(false)
    }

    // Events modal de invitación
    // eslint-disable-next-line no-unused-vars
    function openInvitationModal(companyID) {
        setInviteToCompany(companyID)
        setShowInvitationModal(true)
    }

    function closeInvitationModal() {
        setShowInvitationModal(false)
    }

    // Renderizado de Membresias y Compañias...
    function renderMembership(membership, isThirdCompany) {
        return (
            <div key={membership.Rif} className="border p-2 p-md-3 p-lg-3 mb-2 shadow">
                <h3>{membership.BusinessName}</h3>
                {membership.Companies.map(company => {
                    return renderCompany(company, isThirdCompany)
                })}
                {/* Dibujar boton para agregar compañia solo en membresias propias */}
                {isThirdCompany === false &&
                    <Row className="p-1">
                        <Col xs={0} sm={0} md={7}></Col>
                        {/* <Col xs={12} sm={12} md={5} className="d-flex justify-content-center">
                            <Button onClick={() => {
                                setModalIsForMembership(false);
                                setShowCompanyRegModal(true);
                                setModalMembershipName(membership.BusinessName)
                            }}>Agregar compañia</Button>
                        </Col> */}
                    </Row>
                }
            </div>
        )
    }

    function renderCompany(company, isThirdCompany) {
        return (
            <div className="my-4 mx-1 mx-mdx-2 mx-lg-3" key={company.Keycompany}>
                <div className="d-block h-100 w-100 border rounded p-3 shadow">
                    <Row className="h-100">
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <h4>{company.BusinessName}</h4>
                            <Row >
                                <Col xs={12} sm={8} md={7} lg={7} xl={8}>
                                    <span className="text-muted">{company.Rif}</span>
                                </Col>
                                <Col xs={12} sm={4} md={5} lg={5} xl={4}
                                    className="d-flex justify-content-center mt-2 mt-sm-0">
                                    <Button
                                        onClick={() => handleCompanySelecion(company, isThirdCompany)}>
                                        {t('select')}
                                    </Button>
                                </Col>
                            </Row>
                            {/* Boton de invitar para compañias propias */}
                            {/* {isThirdCompany === false &&
                                <Button onClick={() => openInvitationModal(company.Keycompany) }>Invitar</Button>
                            } */}
                        </Col>
                        {/* <Col md={1} sm={2} xs={2} className="d-flex justify-content-center align-items-center bg-primary">X</Col> */}
                    </Row>
                </div>
            </div>
        )
    }

    function handleCompanySelecion(company, isThirdCompany) {
        companyStore.setSelectedCompany(company, isThirdCompany)
        isThirdCompany ? history.push(INVOICES_ADD) : history.push(DASHBOARD);

    }

    function prepareMembershipCompanyModal(){
        setModalIsForMembership(true)
        setModalMembershipName('')
        setModalIsForDemo(false)
    }

    function showModalForDemo() {
        prepareMembershipCompanyModal()
        setModalIsForMembership(true)
        setModalIsForDemo(true)
        setShowCompanyRegModal(true)
    }

    return (
        <Container className="pt-5">
            <div className="mb-5">
                <i className="fas fa-sign-out-alt float-right btn" onClick={() => authStore.logout()} style={{ fontSize: "22px" }} />
            </div>
            <Row>
                <Col xs={12} sm={12} md={12} lg={6} className="mb-2">
                    <h1 className="text-center">{t('own')}</h1>
                    {companyStore.ownCompanies.length === 0 &&
                        <div className="d-flex justify-content-center">
                            <Button onClick={() => showModalForDemo()}>
                                {t('activate_demo')}
                            </Button>
                        </div>
                    }
                    {companyStore.ownCompanies.length > 0 &&
                        companyStore.ownCompanies.map(membership => {
                            return renderMembership(membership, false)
                        })}
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                    <h1 className="text-center">{t('third_parties')}</h1>
                    {companyStore.thirdPartyCompanies.length === 0 &&
                        <h4 className="border p-3 mb-2">{t('not_yet_invited_to_third_party')}</h4>
                    }
                    {companyStore.thirdPartyCompanies.length > 0 &&
                        companyStore.thirdPartyCompanies.map(membership => {
                            return <div className="border p-3 mb-2">
                                {renderMembership(membership, true)}
                            </div>
                        })}
                </Col>
            </Row>
            {/* <Button onClick={() => { setModalIsForMembership(true); setShowCompanyRegModal(true) }}>Agregar Membresia</Button> */}

            <AddMembershipOrCompany
                parentShowState={showCompanyRegModal}
                parentClose={closeCompanyModal}
                isForDemo={modalIsForDemo}
                isForMembership={modalIsForMembership}
                membershipName={modalMembershipName} />
            <InvitationModal
                parentShowState={showInvitationModal}
                parentClose={closeInvitationModal}
                toCompany={inviteToCompany} />
        </Container>
    )
}

export default inject("companyStore", "authStore")(observer(withRouter(CompanySelection)))