// Devulve el datetime como un string
// con formato entendible y añadiendo
// AM o PM a la hora
const TransformDateTime = datetime => {
    const date = new Date(datetime);

    let dd = date.getDate();
    let mm = date.getMonth() + 1; //Enero es 0!
    const yyyy = date.getFullYear();

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }
    const strDate = dd + '/' + mm + '/' + yyyy;

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // 12 de la noche es 0!!!
    minutes = minutes < 10 ? '0'+minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    
    return strDate+' '+strTime;
}

export default TransformDateTime