import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Form, Button, Row, Col } from 'react-bootstrap';
import Moment from 'moment'

const Filter = ({ withholdingListStore }) => {
    const { t } = useTranslation();

    const { register, handleSubmit } = useForm();

    function onSubmit(data) {
        withholdingListStore.fetch(data.DateFrom, data.DateTo)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={withholdingListStore.isLoading}>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="DateFrom">
                            <Form.Label>{t('date_from')}</Form.Label>
                            <Form.Control name="DateFrom"
                                type="date"
                                ref={register({
                                    required: true,
                                })}
                                defaultValue={Moment().startOf('month').format('YYYY-MM-DD')}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="DateTo">
                            <Form.Label>{t('date_to')}</Form.Label>
                            <Form.Control name="DateTo"
                                type="date"
                                ref={register({
                                    required: true,
                                })}
                                defaultValue={Moment().endOf('month').format('YYYY-MM-DD')}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} className="d-flex align-items-end">
                        <Form.Group className="w-100">
                            <Button
                                type="submit"
                                className="w-100"
                            >
                                {t('search')}
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
        </Form>
    )
}


export default inject('withholdingListStore')(observer(Filter))