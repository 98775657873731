import { decorate, observable, action } from 'mobx';

class SettingsStore {
    isLoading = undefined
    correlative = undefined

    reset(){
        this.isLoading = true
    }

    loadSettings(){
        
        setTimeout(()=>{
            this.correlative = 2
            this.isLoading = false
        },2000)
    }
}

decorate(SettingsStore,{
    isLoading: observable,
    correlative: observable,
    reset: action,
    loadSettings: action,
})

export default new SettingsStore();