import { decorate, observable, action, reaction } from 'mobx';
import userStore from './userStore';
import apiService from 'Service'
import authStore from './authStore'
import companyStore from './companyStore'

class membershipsStore {
  currentMembership = undefined
  membership = {}
  isLoading = false
  serviceUrl = undefined

  constructor() {
    this.loadFromLocalStorage()
    reaction(
      () => this.currentMembership,
      currentMembership => {
        if (currentMembership) {
          let data = JSON.stringify(currentMembership);
          localStorage.setItem('currentMembership', data);
        } else {
          localStorage.removeItem('currentMembership');
        }
      }
    )
  }

    loadFromLocalStorage(){
      try {
        this.currentMembership = JSON.parse(localStorage.getItem('currentMembership'))
        if(this.currentMembership !== null && this.currentMembership !== undefined){
          this.setServiceUrl(this.currentMembership.RouteWebService)
        }
      } catch (e) {
        authStore.logout()
      }
    }

    setCurrentMembership(membership){
      this.currentMembership = membership
      this.setServiceUrl(this.currentMembership.RouteWebService)
    }

    updateLocalMembershipState(data){
      this.membership = {
        ...this.membership,
        ...data
      }
    }

    initProcess(defaultValues){
      this.membership = { ...defaultValues, EMail: userStore.currentUser.EMail }
    }

    sendDemoRequest(){
      this.isLoading = true
      return apiService.Account.activeDemo(this.membership).then(data => {
        companyStore.setOwnCompanies(data.OwnerCompanies)
      }).finally(() => this.isLoading = false)
    }

    sendNewMembershipRequest(){

    }

    forgetAll(){
      this.currentMembership = undefined
      this.serviceUrl = undefined
    }

    setServiceUrl(url){
      this.serviceUrl = url+'/api'
    }
  }

  decorate(membershipsStore, {
    membership: observable,
    currentMembership: observable,
    isLoading: observable,
    serviceUrl: observable,
    setCurrentMembership: action,
    initProcess: action,
    sendDemoRequest: action,
    sendNewMembershipRequest: action,
    forgetAll: action
  })

export default new membershipsStore();
