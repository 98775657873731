import userStore from 'stores/userStore';
import authStore from 'stores/authStore';
import axios from 'axios'
import companyStore from 'stores/companyStore';
import uiStore from 'stores/uiStore'

export const AUTH_SERVICE_URL = 'https://lcwebapi.samsuitecs.com/api';

axios.interceptors.request.use(function (config) {
  if (userStore.currentUser?.token) {
    const token = userStore.currentUser.token;
    config.headers.Authorization = "Bearer "+token

    if (companyStore.selectedCompany) {
      config.headers.keyMembership = companyStore.selectedCompany.Keymembership
      config.headers.keyCompany = companyStore.selectedCompany.Keycompany
    }else{
      config.headers.keyMembership = '' //Obligatorio aunque el usuario no tenga compañia seleccionada.
    }
  }
  
  return config;
});

const handleErrors = err => {
  if (err.response && err.response.status) {
    if (err.response.status === 401) {
      authStore.logout();
    }
    if (err.response.status >= 500 && err.response.status <= 599) {
      uiStore.addNotification(uiStore.appNotifications.INTERNAL_SERVER_ERROR)
    }
  }
  if (err && err.message && err.message === 'Network Error') {
    uiStore.addNotification(uiStore.appNotifications.NETWORK_ERROR)
  }
  throw err
}

const responseBody = res => res

const requests = {
  del: (url, config) =>
    axios
      .delete(`${url}`,config)
      .then(responseBody)
      .catch(handleErrors),
  get: (url,options = {}) =>
    axios
      .get(`${url}`,options)
      .then(responseBody)
      .catch(handleErrors),
  put: (url, data) =>
    axios
      .put(`${url}`, data)
      .then(responseBody)
      .catch(handleErrors),
  post: (url, data) =>
    axios
      .post(`${url}`, data)
      .then(responseBody)
      .catch(handleErrors),
};

export default requests