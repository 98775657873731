import React from 'react'
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {WITHHOLDINGS_GENERATION, INVOICES_ADD} from 'Constants/Routes'

const ActionButton = ({ icon, title, url = '', bgColor = 'bg-primary' }) => {
    return (<>
        <div className={"p-3 w-100 "+bgColor }>
            <Link to={url} className="text-light text-center text-decoration-none">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <i className={icon} style={{ fontSize: '2rem' }} />
                    <p>{title}</p>
                </div>
            </Link>
        </div>
    </>)
}

const QuickActions = () => {
    const { t } = useTranslation();

    return (<>
        <Row>
            <Col xs={6} className="mt-2">
                <ActionButton
                    icon="fas fa-file-invoice"
                    url={WITHHOLDINGS_GENERATION}
                    title={t('generate_withholding')}
                />
            </Col>
            <Col xs={6} className="mt-2">
                <ActionButton
                    icon="fas fa-file-invoice"
                    url={INVOICES_ADD}
                    title={t('add_invoice')}
                />
            </Col>
        </Row>
    </>)
}

export default QuickActions