import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'


const RESOURCE = `/WithHoldings`

const Withholding = {
  add: data =>
    requests.post(`${membershipsStore.serviceUrl+RESOURCE}`, data).then(response => {
      if (response && response.status === 201) return response.data
    }),
  getAll: (initial, take) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/Get/${initial}/${take}`).then(response => {
      if (response && response.status === 200) return response.data
    }),
  getByDate: (from, to, initial, take) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/GetByDate/${from}/${to}/${initial}/${take}`).then(response => {
      if (response && response.status === 200) return response.data
    }),
  getGeneratedTxts: (from, to) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/GetWithholdingTxtGenerate/${from}/${to}`).then(response=>{
      if (response && response.status === 200) return response.data
    })
}
export default Withholding 