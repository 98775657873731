import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import {
    AmountsForm
} from 'Components/Forms'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle';
import useStores from 'hooks/useStores';

enum STEPS {
    AMOUNTS_LIST,
    ADD_AMOUNT,
}

const InvoiceEdit = () => {
    const { t } = useTranslation();

    const { providersStore, invoicesStore } = useStores()

    useTitle('editing_invoice')

    useEffect(() => {
        providersStore.initProcess()
        invoicesStore.initProcess()
        return () => {
            // Limpiar estados al salir
            providersStore.clear()
            invoicesStore.clear()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [step, setStep] = useState(STEPS.AMOUNTS_LIST)

    function nextStep() {
        switch (step) {
            case STEPS.AMOUNTS_LIST:
                sendData()
                break;
            default:
                break;
        }
        if (step !== STEPS.AMOUNTS_LIST) {
            window.scrollTo(0, 1)
        }
    }

    function sendData() {
        invoicesStore.postNewCompleteDocument().then(res => {
            if (res) {
                alert(t('invoice_registered_successfully'))
                window.location.reload()
            }
        })
    }

    function previous() {
        /* switch (step) {
            case STEPS.PROVIDER_INFORMATION:
                providersStore.resetProcess()
                setStep(STEPS.PROVIDER_LOOKUP)
                break;
            case STEPS.INVOICE_INFORMATION:
                setStep(STEPS.PROVIDER_INFORMATION)
                break;
            case STEPS.AMOUNTS_LIST:
                setStep(STEPS.INVOICE_INFORMATION)
                break;
            default:
                break;
        } */
        window.scrollTo(0, 1)
    }

    return (
        <div className="mb-4 mt-2">

            <h3 className="text-center">{t('editing_invoice')}</h3>
            {step === STEPS.AMOUNTS_LIST &&
                <AmountsForm
                    nextStep={nextStep} />}

            <Prompt
                when={step > STEPS.AMOUNTS_LIST}
                message={t('do_you_really_want_to_quit_the_process')}
            />

        </div>
    )
}

export default inject("providersStore", "invoicesStore")(observer(InvoiceEdit))