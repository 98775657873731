const fakeDate = '2000-01-01'

export default {
    withholding: {
        Id: '',
        IdProvider: '',
        Correlative: undefined,
        Date: new Date(),
        DateCreation: fakeDate,
        GenerateTxt: false,
        DateGenerate: fakeDate,
        ImpositivePeriod: ''
    },
    provider: {
        Rif: '',
        BusinessName: '',
        FiscalAddress: '',
        PhoneNumber: '',
        WithholdingPercentageIVA: '',
        WithholdingPercentageISLR: '',
        Active: true,
        SpecialTaxpayer: false,
        Id: '',
        Email: ''
    },
    invoice: {
        Id: '',
        Rif: '',
        BusinessName: '',
        Address: '',
        Phone: '',
        Celular: '',
        IdProvider: '',
        InvoiceNumber: '',
        ControlNumber: '',
        Serie: '',
        EmitDate: undefined,
        GenerateDate: fakeDate,
        ProcessDate: fakeDate,
        IdUserCreator: '',
        IdOperationType: 1, // por defecto 'compra' hasta que se puedan hacer retenciones de venta
        IdDocumentType: 0,
        WithholdingPercentage: 0,
        WithholdingAmmount: 0,
        DueDate: fakeDate,
        IdWithholding: ''
    },
    invoiceDetail: {
        IdInvoice: '',
        Row: 0,
        TaxBase: 0,
        TaxPorcentage: 0,
        TaxAmmount: 0,
        TaxFree: 0,
        Discount: 0,
        TotalAmmount: 0,
        TaxType: 0
    },
    invoicePay: {
        IdInvoice: '',
        Row: 0,
        Ammount: 0,
        Description: '',
        EmitDate: fakeDate,
        GenerateDate: fakeDate,
        PaymentMethod: {
            Id: 0,
            Description: '',
            PayType: 0,
            Active: true,
            IdMoney: ''
        }
    },
    demoMembership: {
        Name: '',
        RIF: '',
        FiscalAddress: '',
        Address: '',
        Phone: '',
        Celular: '',
        EMail: '',
        PeopleContact: '',
        IdCountry: ''
    }

}