import Providers from './Providers'
import Account from './Account'
import DocumentCompletes from './DocumentCompletes'
import Withholding from './Withholding'
import InvoiceDocuments from './InvoiceDocuments'
import Countries from './Countries'
import Reports from './Reports'
import Dashboards from './Dashboards'
import Settings from './Settings'


export default {
    Providers,
    Account,
    DocumentCompletes,
    Withholding,
    InvoiceDocuments,
    Countries,
    Reports,
    Dashboards,
    Settings
}