import React from 'react'
import Utilities from 'Utilities'

const AmountRow = ({ amount, deleteAmountEntry }) => {

    function handleDelete() {
        deleteAmountEntry(amount.Row)
    }

    return <tr key={amount.Row}>
        <td>{Utilities.FormatCurrency(amount.TaxBase)}</td>
        <td>{amount.TaxPorcentage}%</td>
        <td>{Utilities.FormatCurrency(amount.TaxAmmount)}</td>
        <td>{Utilities.FormatCurrency(amount.TaxFree)}</td>
        <td>{Utilities.FormatCurrency(amount.TotalAmmount)}</td>
        <td className="text-center">
            <i style={{ cursor: "pointer" }} class="fas fa-minus-circle text-danger mt-2"
                onClick={handleDelete}></i>
        </td>
    </tr>
}

export default AmountRow