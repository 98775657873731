import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
    ProviderInformationForm,
} from 'Components/Forms'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';
import useTitle from 'hooks/useTitle'
import LoadingSpinner from 'Components/LoadingSpinner'
import {PROVIDERS_LIST} from 'Constants/Routes'
import uiStore from 'stores/uiStore'

const ProviderEdit = ({ providersStore }) => {
    const { t } = useTranslation();
    const title = t('editing')+' '+t('provider')

    const { id } = useParams()
    const history = useHistory()

    useTitle(title)

    useEffect(() => {
        providersStore.idLookUp(id)
        return () => {
            // Limpiar estados al salir
            providersStore.clear()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function nextStep() {
        uiStore.addNotification(uiStore.appNotifications.SUCCESS_EDITED)
        history.push(PROVIDERS_LIST)
    }

    if (providersStore.isLoading && providersStore.provider.Id === undefined) {
        return <div><LoadingSpinner /></div>
    }

    return (
        <div className="mb-4 mt-2">
            <h2>{title}</h2>

            {providersStore.provider.Id === undefined
                ? <h3>{t('record_not_found')}</h3>
                :<>
                <ProviderInformationForm
                    nextStep={nextStep} />
                </>
            }


        </div>
    )
}

export default inject("providersStore")(observer(ProviderEdit))