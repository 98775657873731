/* Pasos del proceso de retención en orden */

enum WITHHOLDING_GENERATION_STEPS {
    IDENTIFICATION_LOOKUP,
    PROVIDER_INFO_INPUT,
    WITHHOLDING_INFO_INPUT,
    INVOICES_SELECTION,
    FINAL_OPTIONS
}

export default WITHHOLDING_GENERATION_STEPS