import React from 'react'
import useTitle from 'hooks/useTitle'
import {useTranslation} from 'react-i18next'

const ListLayout = ({ title, children }) => {
    const { t } = useTranslation();

    useTitle(t('list_of')+' '+t(title))

    return (
        <div className="mt-3">
            <h1>{t('list_of')+' '+t(title)}</h1>
            <div>
                {children}
            </div>
        </div>
    )
}

export default ListLayout