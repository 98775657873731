// Este archivo se mantiene para compatibilidad temporal
// Borrar este archivo ahora provocaría problemas en el componente
//  que se usa para mostrar las invitaciones, el cual tampoco esta terminado
//  pero debe mantenerse mientras tanto para evitar que la app crashee
// Este archivo no hace ninguna función en la app actual
import { decorate, observable, action } from 'mobx';

class CommonStore {
    user = undefined
    memberships = undefined
    invitations = undefined
    company = undefined

    setUser(user) {
        this.user = user;
    }

    setMemberships(memberships){
        this.membership = memberships
    }

    setCompany(company){
        this.company = company
    }

    setInvitations(invitations){
        this.invitations = invitations
    }
}

decorate(CommonStore,{
    user: observable,
    membership: observable,
    invitations: observable,
    company: observable,
    setUser: action,
    setMemberships: action,
    setCompany: action,
})

export default new CommonStore();