import { decorate, observable, action } from 'mobx';
import apiService from 'Service'
import FileDownload from 'js-file-download'
import Utilities from 'Utilities'

class withholdingsStore {

  isFetching = true
  isGenerating = false
  txts = []

  createTxt(fileName = "archivo_txt") {
    this.isGenerating = true
    apiService.Reports.createTxt().then(data => {
      if (data) {
        FileDownload(data, `${fileName}_${Utilities.CurrentDate('dd-mm-yyyy')}.txt`)
      };
    }).finally(()=>this.isGenerating = false)
    .catch((err)=>console.log(err))
  }

  downloadTxt(txtNumber, fileName = "archivo_txt"){
    if(!txtNumber) throw new Error("'txtNumber' param is required")

    return apiService.Reports.downloadTxt(txtNumber).then(data => {
      if (data) {
        FileDownload(data, `${fileName}_${txtNumber}.txt`)
      };
    }).catch((err)=>console.log(err))
  }

  fetchTxts(from, to){
    if(!from) throw new Error("'from' param is required")
    if(!to) throw new Error("'to' param is required")

    this.isFetching = true
    apiService.Withholding.getGeneratedTxts(from, to).then(data =>{
      if(data) this.txts = data
    }).finally(()=>this.isFetching = false)
    .catch((err)=>console.log(err))
  }

  reset(){
    this.txt = []
    this.isFetching  = true
    this.isGenerating = false
  }
}

decorate(withholdingsStore, {
  isFetching: observable,
  isGenerating: observable,
  createTxt: action,
  reset: action,
  fetchTxts: action
})

export default new withholdingsStore();

