import authStore from "stores/authStore";
import commonStore from "stores/commonStore";
import userStore from "stores/userStore";
import uiStore from "stores/uiStore";
import companyStore from "stores/companyStore"
import providersStore from "./providersStore";
import withholdingsStore from "./withholdingsStore";
import invoicesStore from "./invoicesStore";
import configurationStore from "./configurationStore";
import emailSendModalStore from "./Components/emailSendModalStore";

class RootStore {
    authStore: typeof authStore;
    commonStore: typeof commonStore;
    userStore: typeof userStore;
    uiStore: typeof uiStore;
    companyStore: typeof companyStore;
    providersStore: typeof providersStore;
    withholdingsStore: typeof withholdingsStore;
    invoicesStore: typeof invoicesStore
    configurationStore: typeof configurationStore;
    emailSendModalStore: typeof emailSendModalStore

    constructor(){
        this.authStore = authStore
        this.commonStore = commonStore
        this.userStore = userStore
        this.uiStore = uiStore
        this.companyStore = companyStore
        this.providersStore = providersStore
        this.withholdingsStore = withholdingsStore
        this.invoicesStore = invoicesStore
        this.configurationStore = configurationStore
        this.emailSendModalStore = emailSendModalStore
    }
}

export default new RootStore()