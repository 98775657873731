// Devulve el date como un string
// con formato entendible, sin hora.
const TransformDate = datetime => {
    console.log("transformando")
    const date = new Date(datetime);

    let dd = date.getDate();
    let mm = date.getMonth() + 1; //Enero es 0!
    const yyyy = date.getFullYear();

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }
    const strDate = dd + '/' + mm + '/' + yyyy;
    
    return strDate;
}

export default TransformDate