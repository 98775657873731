import React from 'react'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import {
    APP,
    LOGIN,
    DASHBOARD,
    COMPANY_SELECTION,
    PENDING_INVITATIONS,
    WITHHOLDINGS_GENERATION,
    INVOICES_LIST,
    PROVIDERS_LIST,
    PROVIDERS_EDIT,
    INVOICES_ADD,
    WITHHOLDINGS_LIST,
    REPORTS_PURCHASELEDGER,
    COMPANY_SETTINGS,
    TXT_SECTION,
    TXT_GENERATE,
    SETTINGS,
    INVOICES_EDIT
} from 'Constants/Routes'
import Dashboard from 'Pages/Dashboard'
import CompanySelection from 'Pages/Companies/Selection'
import InvoiceRegister from 'Pages/Invoices/Register'
import InvoiceEdit from 'Pages/Invoices/Edit'
import PendingInvitations from 'Pages/PendingInvitations'
import WithholdingGeneration from 'Pages/Withholding/Generation'
import InvoicesList from 'Pages/Invoices/List'
import ProvidersList from 'Pages/Providers/List'
import ProvidersEdit from 'Pages/Providers/Edit'
import WithholdingList from 'Pages/Withholding/List'
import PurchaseLedger from 'Pages/Reports/PurchaseLedger'
import CompanySettings from 'Pages/Companies/Settings'
import TxtList from 'Pages/Txt/List'
import TxtGenerate from 'Pages/Txt/List'

import { Route, Switch } from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner'
import PrivateRoute from './PrivateRoute'
import AdminRoute from './AdminRoute'
import SNavbar from 'Components/SNavbar'
import SSidebar from 'Components/SSidebar'
import Settings from 'Pages/Settings'
import { Container } from 'react-bootstrap'

const AppInside = ({ userStore, uiStore, companyStore, authStore }) => {

    if (authStore.inProgress) {
        return (
            <div className="d-flex justify-content-center align-items-center w-100" style={{ height: '100vh' }}>
                <LoadingSpinner />
            </div>
        )
    }

    if (!userStore.currentUser || !userStore.currentUser.token) {
        return <Redirect to={{ pathname: LOGIN }} />
    }

    if (!companyStore.selectedCompany) {
        return <>
            <Container >
                <Switch>
                    <PrivateRoute path={COMPANY_SELECTION} Component={<CompanySelection />} />
                    <PrivateRoute path={PENDING_INVITATIONS} Component={<PendingInvitations />} />
                    <Redirect to={LOGIN} />
                </Switch>
            </Container>
        </>
    }

    return (
        <>
            <div className={"sidebar-controller " + (uiStore.isSidebarVisible ? "active" : "collapsed")}>
                <SNavbar />
                <SSidebar />
                <div className="content-wrapper">
                    <Container >
                        <Switch>
                            <Route exact path={APP} component={Dashboard} />
                            <Route exact path={DASHBOARD} component={Dashboard} />
                            <PrivateRoute exact path={DASHBOARD} Component={<AdminRoute Component={<Dashboard />} />} />
                            <PrivateRoute exact path={PENDING_INVITATIONS} Component={<PendingInvitations />} />
                            <PrivateRoute exact path={INVOICES_LIST} Component={<InvoicesList />} />
                            <PrivateRoute exact path={INVOICES_ADD} Component={<InvoiceRegister />} />
                            <PrivateRoute exact path={INVOICES_EDIT} Component={<InvoiceEdit />} />
                            <PrivateRoute exact path={PROVIDERS_LIST} Component={<ProvidersList />} />
                            <Route exact path={PROVIDERS_EDIT + ':id'} component={ProvidersEdit} />
                            <PrivateRoute exact path={WITHHOLDINGS_GENERATION} Component={<WithholdingGeneration />} />
                            <PrivateRoute exact path={WITHHOLDINGS_LIST} Component={<WithholdingList />} />
                            <PrivateRoute exact path={REPORTS_PURCHASELEDGER} Component={<PurchaseLedger />} />
                            <PrivateRoute exact path={COMPANY_SETTINGS} Component={<CompanySettings/>} />
                            <PrivateRoute exact path={TXT_SECTION} Component={<TxtList/>} />
                            <PrivateRoute exact path={TXT_GENERATE} Component={<TxtGenerate/>} />
                            <PrivateRoute exact path={TXT_GENERATE} Component={<TxtGenerate/>} />
                            <PrivateRoute exact path={SETTINGS} Component={<Settings/>} />
                            <Redirect to={APP} />
                        </Switch>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default inject("userStore", "uiStore", "companyStore", "authStore")(observer(AppInside))