import React from 'react'
import { Responsive } from 'responsive-react';
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Utilities from 'Utilities'
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'
import ActionButtons from './ActionButtons';


type LargeScreenInvoicesListPropsTypes = {
    invoices: IInvoiceDocument[],
}

const LargeScreenInvoicesList = ({ invoices }: LargeScreenInvoicesListPropsTypes) => {

    const { t } = useTranslation();


    return (
        <Responsive displayIn={["Laptop"]}>
            <Table striped bordered hover>
                <thead>
                    <tr className="text-center">
                        <th>{t('legal_identification')}</th>
                        <th>{t('provider_name')}</th>
                        <th>{t('invoice_number')}</th>
                        <th>{t('control_number')}</th>
                        <th>{t('serie')}</th>
                        <th>{t('total_amount')}</th>
                        <th>{t('date')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map(invoice => {
                        return <tr className="text-center">
                            <td>{invoice.Rif}</td>
                            <td>{invoice.BusinessName}</td>
                            <td>{invoice.InvoiceNumber}</td>
                            <td>{invoice.ControlNumber}</td>
                            <td>{invoice.Serie}</td>
                            <td className="text-right">{Utilities.FormatCurrency(invoice.Total)}</td>
                            <td>{Utilities.TransformDate(invoice.EmitDate)}</td>
                            <td>
                                <ActionButtons invoice={invoice} />
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Responsive>
    )
}

export default LargeScreenInvoicesList