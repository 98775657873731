import CommonStore from './commonStore'
import companyStore from './companyStore'
import userStore from './userStore'
import authStore from './authStore'
import configurationStore from './configurationStore'
import invoicesStore from './invoicesStore'
import providersStore from './providersStore'
import withholdingsStore from './withholdingsStore'
import uiStore from './uiStore'
import membershipsStore from './membershipsStore'
import dashboardStore from './dashboardStore'

export default {
    companyStore,
    userStore,
    CommonStore,
    authStore,
    configurationStore,
    invoicesStore,
    providersStore,
    withholdingsStore,
    uiStore,
    membershipsStore,
    dashboardStore
}