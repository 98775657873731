import { decorate, observable, action } from 'mobx';
import apiService from 'Service'
import defaultValues from 'Constants/DefaultValues'
import providersStore from './providersStore'
import moment from 'moment'
import uiStore from './uiStore';
import AppNotifications from 'Constants/AppNotifications/AppNotifications';

class invoicesStore {
  isLoading = true
  amounts = []
  isEditing

  invoice = defaultValues.invoice

  updateInvoiceState(newData) {
    this.invoice = { ...this.invoice, ...newData }
  }

  setIsLoading(value) {
    this.isLoading = value
  }

  resetProcess() {
    this.setIsLoading(true)
    this.invoice = defaultValues.invoice
    this.setIsLoading(false)
  }

  postNewCompleteDocument() {
    if (providersStore.provider.Id === '') throw new Error('not_valid_provider_id')
    this.setIsLoading(true)
    return apiService.DocumentCompletes.add({
      Invoice: { ...this.invoice, IdProvider: providersStore.provider.Id, EmitDate: moment(this.invoice.EmitDate).format('yyyy-MM-DD') },
      InvoiceDetails: this.amounts,
      InvoicePays: [defaultValues.invoicePay]
    }).then(data => {
      this.setIsLoading(false)
      return data
    }).catch(() => {
      this.setIsLoading(false)
    })
  }

  updateOnService(newData) {
    this.setIsLoading(true)
    this.updateInvoiceState(newData)
    return apiService.Providers.update(this.invoice).then(data => {
      this.updateInvoiceState(data)
      this.setIsLoading(false)
      return true
    }
    ).finally(() => this.setIsLoading(false))
  }

  initProcess(isEditing = false) {
    this.setIsLoading(true)
    this.invoice = defaultValues.invoice
    this.amounts = []
    this.setIsLoading(false)
    this.isEditing = isEditing
  }

  addAmount(amountData) {
    this.amounts.push({ ...defaultValues.invoiceDetail, ...amountData })
  }

  deleteAmount(row) {
    this.amounts = [...this.amounts.filter(x => x.Row !== row)]
  }

  clear() {
    this.isLoading = true
    this.invoices = []
    this.invoice = defaultValues.invoice
    this.amounts = []
  }

  async deleteInvoice(invoice) {
    const result = await apiService.InvoiceDocuments.deleteInvoice(invoice)
    if (result.status === 200) {
      uiStore.addNotification(AppNotifications.INVOICE_DELETE_SUCCESS)
    } else {
      uiStore.addNotification(AppNotifications.INVOICE_DELETE_FAIL)
    }
    return result.status === 200
  }
}

decorate(invoicesStore, {
  invoice: observable,
  isLoading: observable,
  amounts: observable,
  initProcess: action,
  resetProcess: action,
  postNewCompleteDocument: action,
  updateOnService: action,
  addAmount: action,
  deleteAmount: action,
  clear: action,
  deleteInvoice: action,
})

export default new invoicesStore();
