import React from 'react'
import { inject, observer } from 'mobx-react'
import { Responsive } from 'responsive-react';

const SLink = ({ children, uiStore }) => {
  return (<>
    <Responsive displayIn={["Mobile"]}>
      <div  onClick={() => uiStore.closeSidebar()}>
        {children}
      </div>
    </Responsive>
    <Responsive displayIn={["Tablet", "Laptop"]}>
      <div >
        {children}
      </div>
    </Responsive>
  </>
  )
}

export default inject("uiStore")(observer(SLink))