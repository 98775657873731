import React from 'react'
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'



type InvoicesListContextType = {
    invoices: IInvoiceDocument[] | undefined,
    deleteInvoice: (invoice: IInvoiceDocument) => Promise<void>
}

const InvoicesListContext = React.createContext<InvoicesListContextType>({} as InvoicesListContextType)

export default InvoicesListContext