import requests, {AUTH_SERVICE_URL} from './Setup'

const RESOURCE = `${AUTH_SERVICE_URL}/Account`

const Account = {
  userInfo: () =>
    requests.get(`${RESOURCE}/UserInfo`),

  login: (Email, Password) =>
    requests.post(`${RESOURCE}/Login`, { Email, Password }),

  register: (Email, Password, ConfirmPassword) =>
    requests.post(`${RESOURCE}/Register`, { Email, Password, ConfirmPassword }),  

  changePassword: (OldPassword, NewPassword, ConfirmPassword) =>
    requests.post(`${RESOURCE}/ChangePassword`, { OldPassword, NewPassword, ConfirmPassword }),

  deleteCompany: (KeyCompany) =>
    requests.del(`${RESOURCE}/DeleteCompany/`+KeyCompany),

  guestGenerate: (Email, KeyCompany) =>
    requests.get(`${RESOURCE}/GuestGenerate/${Email}/${KeyCompany}`),

  guestApprove: (TokenRegister, KeyCompany) =>
    requests.get(`${RESOURCE}/GuestApprove/${TokenRegister}/${KeyCompany}`),

  guestReject: (TokenRegister, KeyCompany) =>
    requests.get(`${RESOURCE}/GuestReject/${TokenRegister}/${KeyCompany}`),

  getGuestPendient: (Email) =>
    requests.get(`${RESOURCE}/GetGuestPendient/${Email}`),
  
  emailValidate: (Email) =>
    requests.get(`${RESOURCE}/EmailValidate/${Email}/`), /* debe terminae en / */
  activeDemo: (data) =>
    requests.post(`${RESOURCE}/ActiveDemo`,data).then(response => {
      if (response && response.status === 200) return response.data
    }),
};

export default Account