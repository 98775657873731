// Existe un "bug" en Intl que hace que al formatear numeros de 4
// digitos usando el locale "es" el formateo sea incorrecto, ya no se incluye
// el punto que separa los miles

// Ejemplo:
//
//  Formatear 1000 
//  Intl.NumberFormat("es-VE",{minimumFractionDigits:2}).format(1000)
//  
//  Resultado: "1000,00"
//  Esperado: "1.000,00"
//
// Por eso se hace uso del locale "de-DE" que emplea los mismos simbolos para 
// miles y decimales que el "es"

// 16/08/2021: Cambiar la implementación de Intl por algo menos rebuscado y con màs
//             compatibilidad con navegadores antiguos.
    
const FormatCurrency = value =>{
    return new Intl.NumberFormat("de-DE",{minimumFractionDigits:2}).format(value)
}

export default FormatCurrency