import React from 'react'
import { inject, observer } from 'mobx-react'
import { Responsive } from 'responsive-react';
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Utilities from 'Utilities'
import ActionButtons from './ActionButtons'

const LargeScreen = ({ withholdingListStore }) => {
    const { t } = useTranslation();

    return (
        <Responsive displayIn={["Laptop"]}>
            <Table striped bordered hover>
                <thead>
                    <tr className="text-center">
                        <th>{t('provider')}</th>
                        <th>{t('correlative')}</th>
                        <th>{t('date')}</th>
                        <th>{t('txt_generated_date')}</th>
                        <th>{t('impositive_period')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {withholdingListStore.withholdings.map(withholding => {
                        return <tr className="text-center">
                            <td>{withholding.Provider.BusinessName}</td>
                            <td>{withholding.Correlative}</td>
                            <td>{Utilities.TransformDate(withholding.Date)}</td>
                            <td>
                                {withholding.DateGenerate === "2000-01-01T00:00:00"
                                    ? t('txt_not_generated_yet')
                                    : Utilities.TransformDate(withholding.DateGenerate)
                                }
                            </td>
                            <td>{withholding.ImpositivePeriod}</td>
                            <td className="text-center" >
                                <ActionButtons 
                                    idWithholding={withholding.Id}
                                    correlative={withholding.Correlative}
                                    providerEmail={withholding.Provider.Email}
                                />
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Responsive>
    )
}


export default inject('withholdingListStore')(observer(LargeScreen))