import { decorate, observable, action, reaction } from 'mobx';
import authStore from './authStore'
import userStore from './userStore'
import configurationStore from './configurationStore'
import membershipsStore from './membershipsStore'

class CompanyStore {
  selectedCompany: any | undefined = undefined
  ownCompanies: any[] = []
  thirdPartyCompanies: any[] = []

  constructor() {
    this.loadFromLocalStorage()
    reaction(
      () => this.selectedCompany,
      selectedCompany => {
        if (selectedCompany) {
          let data =JSON.stringify(selectedCompany);
          localStorage.setItem('selectedCompany', data);
        } else {
          localStorage.removeItem('selectedCompany');
        }
      }
    );
    reaction(
      () => this.ownCompanies,
      ownCompanies => {
        if (ownCompanies) {
          let data =JSON.stringify(ownCompanies);
          localStorage.setItem('ownCompanies', data);
        } else {
          localStorage.removeItem('ownCompanies');
        }
      }
    );
    reaction(
      () => this.thirdPartyCompanies,
      thirdPartyCompanies => {
        if (thirdPartyCompanies) {
          let data =JSON.stringify(thirdPartyCompanies);
          localStorage.setItem('thirdPartyCompanies', data);
        } else {
          localStorage.removeItem('thirdPartyCompanies');
        }
      }
    );
  }

  loadFromLocalStorage(){
    if(userStore.currentUser){
      try{
        this.selectedCompany = JSON.parse(localStorage.getItem('selectedCompany')!)
        this.ownCompanies = JSON.parse(localStorage.getItem('ownCompanies')!)
        this.thirdPartyCompanies = JSON.parse(localStorage.getItem('thirdPartyCompanies')!)
        if (this.ownCompanies === null ||
          this.thirdPartyCompanies === null) {
            authStore.logout()
        }
      }catch(e){
      }
    }
    
  }

  setSelectedCompany(company: any, isThirdParty: boolean) {
    this.selectedCompany = {...company,isThirdParty: isThirdParty}
    let membership = this.ownCompanies.find(memb => memb.Keymembership === company.Keymembership)
    if(!membership){
      membership = this.thirdPartyCompanies.find(memb => memb.Keymembership === company.Keymembership)
    }
    membershipsStore.setCurrentMembership(membership)
    configurationStore.setDocumentsType(membership.DocumentTypes)
    configurationStore.setOperationsType(membership.OperationTypes)
    configurationStore.setTaxes(membership.Taxes)
  }

  setOwnCompanies(companies: any[]){
    this.ownCompanies = companies
  }

  setThirdPartyCompanies(companies: any[]){
    this.thirdPartyCompanies = companies
  }

  fogetSelectedCompany(){
    this.selectedCompany = undefined
    membershipsStore.forgetAll()
    configurationStore.forgetAll()
  }

  forgetAll(){
    this.ownCompanies = []
    this.thirdPartyCompanies = []
    this.selectedCompany = undefined
    configurationStore.forgetAll()
  }

  updateCompanyCurrentCorrelative(newCorrelative: any){
    this.selectedCompany.Settings.WithholdingCorrelative = newCorrelative
  }

  increaseCompanyCurrentCorrelativeByOne(){
    const value = parseInt(this.selectedCompany.Settings.WithholdingCorrelative)
    const newValue = value + 1
    this.selectedCompany = {
      ...this.selectedCompany,
      Settings: {
        ...this.selectedCompany.Settings,
        WithholdingCorrelative: newValue
      }
    }
  }
}

decorate(CompanyStore, {
  selectedCompany: observable,
  ownCompanies: observable,
  thirdPartyCompanies: observable,
  setSelectedCompany: action,
  setOwnCompanies: action,
  fogetSelectedCompany: action,
  setThirdPartyCompanies: action,
  updateCompanyCurrentCorrelative: action,
  increaseCompanyCurrentCorrelativeByOne: action
})

export default new CompanyStore();
