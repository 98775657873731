import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'
import IInvoiceDocumentComplete from 'interfaces/service/Invoice/IInvoiceDocumentComplete'

const RESOURCE = `/DocumentCompletes`

const DocumentCompletes = {
  add: (data: any) =>
    requests.post(`${membershipsStore.serviceUrl+RESOURCE}`, data).then(response => {
      if (response.status === 201) return response.data
    }),
  getSingle: (id: string) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/${id}`).then(rsponse => {

    }),
  getPending: (IdProvider: string) =>
    requests.get(`${membershipsStore.serviceUrl+RESOURCE}/GetPending/${IdProvider}`).then(response => {
      if (response && response.status === 200) return response.data as IInvoiceDocumentComplete[]
    }),
}

export default DocumentCompletes