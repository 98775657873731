import React from 'react'
import { inject, observer } from 'mobx-react'
import { Responsive } from 'responsive-react';
import { Row } from 'react-bootstrap'
import WithholdingCard from './WithholdingCard'

const SmallScreen = ({ withholdingListStore }) => {

    return (
        <Responsive displayIn={["Mobile", "Tablet"]}>
            <Row>
                {withholdingListStore.withholdings.map(withholding => {
                    return <WithholdingCard withholding={withholding}/>
                })}
            </Row>
        </Responsive>
    )
}


export default inject('withholdingListStore')(observer(SmallScreen))