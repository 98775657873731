import requests from './Setup'
import membershipsStore from 'stores/membershipsStore'
import IInvoiceDocument from 'interfaces/service/Invoice/IInvoiceDocument'

const RESOURCE = `/InvoiceDocuments`


const InvoiceDocuments = {
  add: (data: any) =>
    requests.post(`${membershipsStore.serviceUrl + RESOURCE}`, data).then(response => {
      if (response && response.status === 201) return true
    }),
  getAll: async (initial: number, take: number): Promise<IInvoiceDocument[]> => {
    const response = await requests.get(`${membershipsStore.serviceUrl + RESOURCE}/Get/${initial}/${take}`)
    return response.data as IInvoiceDocument[]
  },
  deleteInvoice: async (invoice: IInvoiceDocument) => {
    const response = await requests.del(`${membershipsStore.serviceUrl + RESOURCE}/${invoice.Id}`)
    return response
  }
}

export default InvoiceDocuments