import React, { useEffect } from 'react'
import { Provider, inject, observer } from 'mobx-react'
import LoadingSpinner from 'Components/LoadingSpinner'
import { ListLayout } from 'Components/Layouts'
import { useTranslation } from 'react-i18next'
import LargeScreen from './LargeScreen'
import SmallScreen from './SmallScreen'
import WithholdingEmailSendModal from 'Components/Modals/WithholdingEmailSendModal'
import Filter from './Filter'
import withholdingListStore from 'stores/Pages/Withholding/withholdingListStore'

const WithholdingList = inject('withholdingListStore')(observer(({ withholdingListStore }) => {
    const { t } = useTranslation();

    useEffect(() => {
        withholdingListStore.fetch()
        return () => {
            withholdingListStore.clear()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ListLayout title='withholdings'>
                <Filter />
                
                {withholdingListStore.isLoading && <LoadingSpinner />}

                {!withholdingListStore.isLoading && withholdingListStore.withholdings &&
                    <>
                        {withholdingListStore.withholdings.length === 0 && <h2>{t('not_withholdins_found')}</h2>}
                        {withholdingListStore.withholdings.length > 0 &&
                            <>
                                <LargeScreen />
                                <SmallScreen />
                                <WithholdingEmailSendModal/>
                            </>
                        }
                    </>
                }
            </ListLayout>
        </>
    )
})) 


export default (()=>{
    return <Provider withholdingListStore={withholdingListStore} 
        ><WithholdingList/>
    </Provider>
})