import NotificationsTypes from '../NotificationsTypes'
import STANDARD_DISMISS from './StandardDismiss'

const INVOICE_NOTIFICATIONS = {
    INVOICE_DELETE_SUCCESS: {
        type: NotificationsTypes.SUCCESS,
        message: 'invoice_deleted_successfully',
        dismiss: STANDARD_DISMISS
    },
    INVOICE_DELETE_FAIL: {
        type: NotificationsTypes.ERROR,
        message: 'invoice_deletion_error',
    }
}


export default INVOICE_NOTIFICATIONS
