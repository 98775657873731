import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingSpinnerAlt from 'Components/LoadingSpinnerAlt'
import txtStore from 'stores/txtStore'

const Actions = ({txt}) => {
    const { t } = useTranslation()

    const [isDownloading, setIsDownloading] = useState(false)

    function download(){
        setIsDownloading(true)
        txtStore.downloadTxt(txt.GenerateNumber)
            .finally(()=>setIsDownloading(false))
    }

    return (<div>
        <>
            {isDownloading && 
            <div className="d-inline-block" 
                style={{width: '42px', height: '30px',textAlign: 'center'}}>
                <LoadingSpinnerAlt/>
            </div>}
            {!isDownloading && <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-download`}>
                        {t('download')}
                    </Tooltip>
                }
            >
                <i className="fas fa-download btn" onClick={download}/>
            </OverlayTrigger>}
        </>
    </div>)
}

export default Actions