import { decorate, observable, action, reaction } from 'mobx';

class UserStore {

  loadingUser: boolean = false;
  currentUser: any | undefined = undefined
  updatingUser: boolean = false

  constructor() {
    this.loadFromLocalStorage()
    reaction(
      () => this.currentUser,
      currentUser => {
        if (currentUser) {
          window.localStorage.setItem('currentUser', JSON.stringify(currentUser));
        } else {
          window.localStorage.removeItem('currentUser');
        }
      }
    );
  }

  loadFromLocalStorage(){
    try{
      this.setCurrentUser(JSON.parse(window.localStorage.getItem('currentUser')!))
    }catch(e){
      this.setCurrentUser(undefined)
      window.localStorage.removeItem('currentUser');
    }
  }

  setCurrentUser(user: any) {
    this.currentUser = user;
  }

  forgetAll() {
    this.currentUser = undefined;
  }
}

decorate(UserStore,{
  currentUser: observable,
  loadingUser: observable,
  setCurrentUser: action,
  forgetAll: action
})

export default new UserStore();
